/*******************************************************
	Footer (mobile first)
********************************************************/

.nm-footer {
	background-color: var(--color-grey-70);
	overflow-wrap: break-word;
	padding-bottom: var(--space-xxxl);
	position: relative;
	width: 100%;
}

.nm-footer.nm-one-footer {
	padding-bottom: 0;
}

.nm-footer__copyright {
	border-top: 1px solid var(--color-grey-60);
	color: var(--color-grey-40);
	padding-top: var(--space-m);
}

.nm-footer__copyright a {
	color: var(--color-grey-30);
	text-decoration: none;
}

.nm-footer__copyright a:hover {
	color: var(--color-white);
}
