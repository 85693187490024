.nm-md-notification-bar {
	background: #666;
	height: auto;
	overflow: hidden;
	transition: max-height .5s;
}

.nm-md-notification-bar.nm-module {
	margin: 0;
}

.nm-js-enabled .nm-md-notification-bar {
	max-height: 0;
}

.nm-md-notification-bar.nm-j-notification-foldout {
	max-height: 999px;
}

.nm-md-notification-bar:nth-child(2n) {
	background: #ccc;
}

.nm-md-notification-bar .nm-md-nb-inner {
	margin: 0 auto;
	max-width: 1177.6px;
	min-width: 294px;
	padding-bottom: 13px;
	padding-top: 13px;
	width: 100%;
}

.nm-md-notification-bar .nm-md-nb-inner .nm-md-nb-headline {
	padding-right: 24px; /* Space for Close-Icon */
	padding-top: 4px;
}

.nm-md-notification-bar .nm-md-nb-cancel-wrapper {
	position: relative;
	width: 100%;
}

.nm-md-notification-bar .nm-md-nb-cancel {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAYAAAARIY8tAAAAAXNSR0IArs4c6QAAAc5JREFUSA21ls8rRFEUxz0/QjIWokZR2A6RhZVmJTs2LCysZ4piN/kTbCQ7O6URJVZIKbJjQ7FgwUZRmLJQluPzfb96M/PmzdPMu/WZe+4533POm3vnvTdGXcDI5/NNhCdhBsahB2LwAa9wDkeGYdwxhx8UNmAeXuAKVmAUuqAZeiEJayDNGYyE6oCwDQ7hBiYqJaFphDS8w3KgHkEMbmELtD2hB/o+O3fdN4lgPRzDpq8ghJPcdniAdIkc5xJcQENJ8B8O8gfgEwbdNBbqrD1MuM4qDOqswp5bgkWqwOFGLINYS5HLXAb4W4l9QbzeTtTv/KBMkW78j4iHvHHWGdZZr8+xuS9+sU9h2vQh/gHdQL6D2By8gdmEOQNPEPdNwElsAbIyOuC7nNDxo1ETndMGBBZXDpokXMrQqT87hYJmdFnQmArSKYYmAfc6gxx0yhk0EGvPx2ARtlkPB+mJqaZqm90qnYH2XAdt7jmzs11lm6CxzsBucIJj1uxW9KGicK3ZG5Iedr0+r01sB1KmTwbsewXV2NTSfZAD66Iwor2TdbU0qdWzqJ9ahc8iu0G0T1O7SXTvAzXQ4OtF90azWphNavZONpyifjPfpup/FX/geSYn7+M6YgAAAABJRU5ErkJggg==");
	background-position: top right;
	background-repeat: no-repeat;
	cursor: pointer;
	display: inline-block;
	height: 24px;
	margin: 0;
	position: absolute;
	right: 0;
	top: 2px;
	width: 24px;
}

.nm-md-notification-bar .nm-md-nb-inner li {
	display: inline-flex;
	margin-bottom: 5px;
	margin-right: 30px;
	margin-top: 12px;
}

.nm-md-notification-bar .nm-md-nb-inner li:last-child {
	margin-right: 0;
}

@media (max-width: 1280px) {
	.nm-md-notification-bar .nm-md-nb-inner {
		width: 92%;
	}
}
