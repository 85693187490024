.nm-md-iframe {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.nm-layer .nm-md-iframe {
	margin-bottom: 0;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x1,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x2,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x4,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x6,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT16x9,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT4x3 {
	height: 0 !important;
	position: relative;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x1 {
	padding-bottom: 100%;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x2 {
	padding-bottom: 200%;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x4 {
	padding-bottom: 400%;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x6 {
	padding-bottom: 600%;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT16x9 {
	padding-bottom: 56.25%;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT4x3 {
	padding-bottom: 75%;
}

.nm-md-iframe > iframe {
	border: none;
	display: block;
	width: 100%;
}

.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x1 > iframe,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x2 > iframe,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x4 > iframe,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT1x6 > iframe,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT16x9 > iframe,
.nm-md-iframe.nm-md-iframe-pageformat-FORMAT4x3 > iframe {
	height: 100%;
	position: absolute;
}
