/* specific markets serve their main navigation as dropdown */

/*	Dropdown navigation
	css scope is nm-navigation-is-dropdown
============================================================================= */

.nm-navigation-is-dropdown {
	overflow: visible;
}

.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item {
	float: left;
	width: auto;
}

/* models */
.nm-navigation-is-dropdown .nm-navigation-carline-main-item {
	position: static;
}

.nm-header-is-half-way-special .nm-navigation-header-dropdown-item .nm-subnavigation-linklist {
	top: 36px;
}

.nm-navigation-is-dropdown .nm-navigation-sitemap-linklist {
	display: inline-block;
}

/* TODO: Better qualifying with a class */
.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > label {
	display: none;
}

.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item {
	position: relative;
}

.nm-navigation-is-dropdown .nm-navigation-dropdown-visibility-switch {
	display: block;
	height: 0;
	position: absolute;
	visibility: hidden;
	width: 0;
}

/* dropdown panels */
.nm-navigation-is-dropdown .nm-subnavigation-linklist {
	background-color: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5);
	display: none;
	height: auto;
	left: 0;
	min-width: 200px;
	padding: 0;
	position: absolute;
	top: 70px;
	width: auto;
	z-index: -1;
}

.nm-navigation-is-dropdown .nm-subnavigation-linklist .nm-navigation-sitemap-linklist-item {
	border-bottom: 1px solid #d0d3d4;
	height: auto;
	padding: 10px 10px 0;
}

/* typo - it's starTpage */
.nm-navigation-is-dropdown .nm-navigation-header-item-startpage-link {
	display: none;
}

.nm-navigation-is-dropdown .nm-navigation-startpage-link-visible-on-desktop {
	display: inline-block;
}

.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item > .nm-navigation-dropdown-visibility-switch-label > a span.hovertouch {
	display: block;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item > .nm-navigation-dropdown-visibility-switch-label > a:hover span.hovertouch,
.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item > .nm-navigation-dropdown-visibility-switch-label > a:focus span.hovertouch {
	display: none;
}

.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item .nm-navigation-sitemap-linklist {
	height: auto;
	overflow: visible;
}

.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item:first-of-type a,
.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item:first-of-type label span {
	margin-left: 0;
}

@media all and (min-width: 1000px) {
	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item .nm-subnavigation-linklist {
		display: none;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item:hover .nm-subnavigation-linklist {
		display: block;
		z-index: 9999;
	}
}

@media all and (max-width: 1110px) {
	.nm-navigation-is-dropdown .nm-navigation-header-link {
		padding: 15px 0;
	}

	.nm-navigation-is-dropdown .nm-subnavigation-linklist {
		top: 50px;
	}
}

@media all and (max-width: 999px) {
	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items #nm-navigation-dropdown-menu ~ .nm-navigation-dropdown-menu-content {
		top: 50px;
		z-index: 1050;
	}

	.nm-header-is-half-way-special .nm-navigation-is-dropdown .nm-header-nav-dropdown-items #nm-navigation-dropdown-menu ~ .nm-navigation-dropdown-menu-content {
		top: 100px;
		z-index: 1050;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-item-startpage-link {
		display: inline-block;
	}

	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items .nm-navigation-sitemap-linklist {
		display: none;
	}

	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > label {
		display: block;
		padding: 10px 0;
	}

	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items #nm-navigation-dropdown-menu ~ .nm-navigation-dropdown-menu-content {
		background-color: #fff;
		box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
		display: none;
		left: 0;
		margin-left: -5%;
		position: absolute;
		top: 50px;
		width: 110%;
	}

	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items #nm-navigation-dropdown-menu:checked ~ .nm-navigation-dropdown-menu-content {
		display: block;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item .nm-navigation-sitemap-linklist {
		position: relative;
	}

	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > ul > li.nm-navigation-header-static-item,
	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > ul > li.nm-navigation-header-dropdown-item {
		border-top: 1px solid #d0d3d4;
		width: 100%;
	}

	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > ul > li.nm-navigation-header-dropdown-item > a,
	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > ul > li.nm-navigation-header-dropdown-item label span,
	.nm-navigation-is-dropdown .nm-header-nav-dropdown-items > ul > li.nm-navigation-header-static-item > a {
		margin: 0;
		padding: 14px 20px 14px 35px;
		width: calc(100% - 55px);
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item > input[type=checkbox]:checked ~ .nm-subnavigation-linklist,
	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item:hover > input[type=checkbox]:checked ~ .nm-subnavigation-linklist {
		background-color: #f2f2f2;
		border-top: 1px solid #d0d3d4;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		display: block;
		height: auto;
		min-width: 200px;
		padding: 0;
		position: static;
		width: auto;
		z-index: 99;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item > input[type=checkbox] + label {
		background: no-repeat right 20px center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjE4RkY2RDVFQjkzMTFFMjgyMDVFNDNGRjlEMEVCMzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjE4RkY2RDZFQjkzMTFFMjgyMDVFNDNGRjlEMEVCMzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGMThGRjZEM0VCOTMxMUUyODIwNUU0M0ZGOUQwRUIzNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMThGRjZENEVCOTMxMUUyODIwNUU0M0ZGOUQwRUIzNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuAkxtMAAAA0SURBVHjaYmRgYPjPACL+/2dkQAKMjIxgcSZ0AQw2UCeKADIAmcqEzXhkMSZ0AXQ2QIABAMDwGgESpmBSAAAAAElFTkSuQmCC");
		display: block;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item > input[type=checkbox]:checked + label {
		background: no-repeat right 20px center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRjg3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTdCMjUxRjk3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGNjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGNzc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgslqbkAAABNSURBVHjaYvr//z8DDJ9hMP4PwshiTAxQcJbR5D+ngTIDCIPYMHEWZEmhcHew4DuGnWAxoHmMLMiSEhVJjFCN/2GKWLBIwthgRQABBgB5LTGo9okstAAAAABJRU5ErkJggg==");
		display: block;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item .nm-subnavigation-linklist li {
		padding: 0;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-dropdown-item .nm-subnavigation-linklist li a {
		display: block;
		margin: 0;
		padding: 14px 20px 14px 35px;
		width: calc(100% - 55px);
	}

	.nm-navigation-is-dropdown .nm-navigation-header-sitemap .nm-navigation-header-link {
		margin: 0; /* overwrites default */
		padding-bottom: 24px;
		padding-left: 0;
		padding-top: 4px;
	}
}
