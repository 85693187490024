/*	process navigation ( enquiry, dealer search)
============================================================================= */

.nm-process-navigation {
	counter-reset: li;
	float: left;
	font-size: 3.6rem;
	margin-bottom: 30px;
	width: 100%;
}

.nm-process-navigation li {
	background: url("../assets/img/sprite-main.png") right -550px no-repeat #e8e9eb;
	border-bottom: 1px solid #e8e9eb;
	border-top: 1px solid #e8e9eb;
	display: inline-block;
	padding: 8px 1.5%;
	position: relative;
	width: 22%;
}

.nm-process-navigation-5 li {
	width: 16.3%;
}

.nm-process-navigation > li::before {
	content: counter(li) " ";
	counter-increment: li;
	font-family: AudiTypeExtended, Verdana, sans-serif;
	left: 10px;
	position: absolute;
	top: 11px;
}

.nm-process-navigation li:last-child {
	background-image: none;
	border-right: 1px solid #e8e9eb;
	box-sizing: border-box;
}

.nm-process-navigation li:last-child::after {
	background-color: #ebe8e9;
	background-image: none;
	border: 1px solid #ebe9e9;
	border-left: 0;
	content: "";
	display: block;
	height: 46px;
	position: absolute;
	right: -22px;
	top: -1px;
	width: 21px;
}

.nm-process-navigation .nm-active {
	background: url("../assets/img/sprite-main.png") right -652px no-repeat #fff;
}

.nm-process-navigation li:last-child.nm-active::after {
	background-color: #fff;
	border: 1px solid #e8e9eb;
	border-left: none;
}

.nm-process-navigation .nm-deselected,
.nm-process-navigation .nm-deselected a {
	color: #abb1b4;
	cursor: default;
}

.nm-process-navigation a {
	display: inline-block;
	font-size: 1.1rem;
	margin-left: 30px;
}

.nm-process-navigation .nm-active a::before {
	background: url("../assets/img/sprite-main.png") 100.5% -601px no-repeat #fff;
	content: "";
	display: block;
	height: 46px;
	left: -16px;
	overflow: visible;
	position: absolute;
	top: 0;
	width: 16px;
}

.nm-process-navigation .nm-active:first-child a::before {
	background-image: none;
	border-left: 1px solid #e8e9eb;
	content: "";
	display: block;
	height: 46px;
	left: 0;
	position: absolute;
	top: 0;
}

.nm-navigation-derivative-main-list a.nm-navigation-sub-overview-link {
	display: none;
}

@media screen and (max-width: 859px) {
	.nm-state-is-responsive .nm-process-navigation li {
		background: none;
		background-color: #e8e9eb;
		border-bottom-color: #fff;
		border-top-color: #fff;
		display: block;
		padding: 8px 10px;
		width: -webkit-calc(100% - 20px);
		width: calc(100% - 20px);
	}

	.nm-state-is-responsive .nm-process-navigation .nm-active {
		background: #fff;
	}

	.nm-state-is-responsive .nm-process-navigation li:first-child {
		border-top-color: #e8e9eb;
	}

	.nm-state-is-responsive .nm-process-navigation li:last-child {
		border-bottom-color: #e8e9eb;
		border-right: none;
		box-sizing: inherit;
	}

	.nm-state-is-responsive .nm-process-navigation li:last-child::after {
		display: none;
	}

	.nm-state-is-responsive .nm-process-navigation .nm-active a::before,
	.nm-state-is-responsive .nm-process-navigation .nm-active:first-child a::before {
		display: none;
	}

	.nm-state-is-responsive .nm-process-navigation a {
		width: 95%;
	}
}
