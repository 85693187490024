.nm-modal-layer {
	align-items: center;
	background: rgba(0, 0, 0, .8);
	display: flex;
	height: 100%;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;
}

.nm-modal-layer__shader-click-area {
	display: flex;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 101;
}

.nm-modal-layer[data-layer-active="false"],
.nm-modal-layer[data-layer-active="false"] .nm-modal-layer__shader-click-area,
.nm-modal-layer[data-layer-active="false"] .nm-modal-layer__content {
	opacity: 0;
	transition: visibility 0s .25s, opacity .25s linear;
	visibility: hidden;
}

.nm-modal-layer[data-layer-active="true"],
.nm-modal-layer[data-layer-active="true"] .nm-modal-layer__shader-click-area,
.nm-modal-layer[data-layer-active="true"] .nm-modal-layer__content {
	opacity: 1;
	transition: opacity .25s linear;
	visibility: visible;
}

.nm-modal-layer--no-transition[data-layer-active="true"],
.nm-modal-layer--no-transition[data-layer-active="false"] {
	transition: none;
}

.nm-modal-layer--hidden-background {
	background: none;
}

body.nm-modal-layer--open {
	overflow: hidden; /* prevent scroll on body, if layer is open */
}

.nm-modal-layer__content {
	align-self: flex-start;
	background: #fff;
	margin: auto;
	position: relative;
	z-index: 102;
}

.nm-modal-layer__content .nm-layer-title {
	display: none;
}

.nm-modal-layer__content--hide {
	opacity: 0;
}

.nm-modal-layer__content--show {
	opacity: 1;
}

.nm-modal-layer__inner {
	margin: var(--space-xxxl) 4%;
}

.nm-modal-layer__header {
	padding: var(--space-xs) 0 var(--space-l);
	position: relative;
}

.nm-modal-layer .modal-layer-close {
	cursor: pointer;
	height: 32px;
	position: absolute;
	right: var(--space-m);
	top: var(--space-m);
	width: 32px;
}

.nm-modal-layer__headline {
	display: inline-block;
}

.nm-modal-layer__footer {
	display: flex;
}

.nm-modal-layer__confirm-button {
	margin-top: var(--space-xl);
}

@media all and (min-width: 768px) {
	.nm-modal-layer__content {
		align-self: center;
		max-width: 768px;
	}

	.nm-modal-layer__header {
		padding-top: var(--space-m);
	}
}
