/* nemo debug styles */
.nm-state-is-debug::after {
	background: #f00;
	color: #fff;
	content: "Debug-Modus ist aktiviert";
	display: block;
	font-weight: bold;
	height: 20px;
	padding: 5px;
	position: fixed;
	right: 0;
	text-align: right;
	top: 0;
	width: 200px;
	z-index: 9999;
}

.nm-debug-item {
	display: none;
}

.nm-state-is-debug .nm-debug-item {
	clear: both;
	display: block;
}

.nm-state-is-debug [data-tracking-link],
.nm-state-is-debug .nm-j-configurator-delegate-click {
	outline: 1px solid #00f;
}

.nm-state-is-debug .nm-j-configurator-container-hide {
	background: rgba(255, 0, 0, .5);
	display: block;
}

.nm-state-is-debug .nm-j-configurator-status_00000 {
	background: rgba(255, 0, 0, .5);
	display: block;
}

.nm-state-is-debug .nm-engine-list .nm-j-configurator-status_00000 {
	display: table-row;
}

/* TODO: remove engine-selection-list once no more needed */
.nm-state-is-debug .nm-module:not(.nm-md-tiles):not(.nm-md-engine-selection-list) .nm-j-configurator-item,
.nm-state-is-debug .nm-module:not(.nm-md-tiles).nm-j-configurator-item,
.nm-state-is-debug .nm-module.nm-md-engine-selection-list .nm-debug-item,
.nm-state-is-debug .nm-module.nm-md-tiles .nm-debug-item {
	position: relative;
}

/* TODO: remove engine-selection-list once no more needed */
.nm-state-is-debug .nm-module:not(.nm-md-tiles) .nm-j-configurator-item::before,
.nm-state-is-debug .nm-module:not(.nm-md-tiles).nm-j-configurator-item::before,
.nm-state-is-debug .nm-module.nm-md-engine-selection-list .nm-debug-item::before {
	background-color: rgba(255, 0, 0, .75);
	bottom: 0;
	color: #fff;
	content: "MBV: " attr(data-mbvid) " " attr(data-message);
	padding: 5px;
	position: absolute;
	right: 0;
	z-index: 10;
}

.nm-state-is-debug .nm-module.nm-md-tiles .nm-j-configurator-item:hover .nm-debug-item {
	background-color: rgba(255, 0, 0, .75);
	padding: 5px;
}

.nm-state-is-debug .nm-module.nm-md-tiles .nm-j-configurator-item:hover .nm-tile-element-info {
	overflow: visible;
}

.nm-state-is-debug .nm-module.nm-md-tiles .nm-j-configurator-item:hover .nm-debug-item::after {
	color: #fff;
	content: "MBV: " attr(data-mbvid) " " attr(data-message);
	display: inline;
	margin-left: 5px;
	z-index: 10;
}

.nm-version-info {
	display: none !important;
}

.nm-state-is-debug .nm-version-info {
	background: rgba(255, 0, 0, .75);
	color: #fff;
	display: block !important;
	font-size: 14px;
	padding: 20px;
	position: fixed;
	right: 0;
	top: 40px;
	z-index: 9999;
}

.nm-version-info dt,
.nm-version-info dd {
	float: left;
}

.nm-version-info dt {
	clear: left;
	font-weight: bold;
	margin-right: 10px;
}

.nm-version-info dt::after {
	content: ":";
}

.nm-version-info dd.nm-activated-scopes,
.nm-version-info dd.nm-activated-context-scopes {
	font-size: 75%;
	max-width: 150px;
}

.nm-version-info dd.nm-activated-scopes::before {
	content: attr(data-scopes);
}

.nm-version-info dd.nm-activated-context-scopes::before {
	content: attr(data-context-scopes);
}

.nm-state-is-debug [data-renderimage-type]::before {
	background: #ff0;
	color: #000;
	content: "Rendering: " attr(data-renderimage-type) "/" attr(data-renderimage-view);
	display: block;
	font-weight: bold;
	left: 0;
	padding: 5px;
	position: absolute;
	top: 0;
}

.nm-j-configurator-item[data-visibility="hidden"] {
	display: none;
}

.nm-state-is-debug .nm-j-configurator-item[data-visibility="hidden"] {
	display: block;
}
