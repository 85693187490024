/*********************************************************
	Footer Navigation (links and social icons)
*********************************************************/

.nm-footer__navigation-wrap {
	box-sizing: border-box;
	margin: 15px auto 0;
	max-width: 1177.6px;
	min-width: 0;
	width: 92%;
}

/* Light BG used for asian market ie. china */
.nm-footer .nm-footer-light-bg + .nm-footer__navigation-wrap {
	margin-top: 30px;
}

.nm-footer__navigation {
	align-items: flex-start;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	margin-bottom: calc(var(--space-l) - var(--space-s));
	width: 100%;
}

.nm-footer__navigation-list {
	width: 100%; /* WA: width prevents overflowing content in edge */
}

.nm-footer__navigation-list-item {
	float: left;
}

.nm-footer__navigation-list-link {
	color: var(--color-white);
	margin-bottom: var(--space-m);
	margin-right: var(--space-m);
}

.nm-footer__navigation-list-link:hover {
	color: var(--color-grey-30);
}

/* social icons */
.nm-footer__social-list {
	display: flex;
	flex-flow: row wrap;
	margin: calc(var(--space-xxl) - var(--space-m)) 0 0 0;
}

.nm-footer__social-list-link {
	height: 48px;
	width: 48px;
}

/* tmp fix - custom icons will be delivered as bg images as long as svg are not provided in cms */
.nm-footer__social-list-link {
	display: inline-block !important;
	padding-left: 0;
}

.nm-footer__social-list-link::before {
	background-color: transparent;
	height: 48px;
	width: 48px;
}

.nm-footer__social-list-link:hover {
	color: #4c4c4c;
}

.nm-footer__social-list-link:hover::before {
	background-color: transparent;
}

/* end tmp fix */

.nm-footer__social-list-link:hover svg {
	fill: #b3b3b3;
}

.nm-footer .nm-footer__social-list [class^="nm-icon"] {
	display: inline-block;
	fill: #fff;
	height: 4.8rem;
	stroke-width: 0;
	width: 4.8rem;
}

.nm-footer__social-list-item {
	margin: 0 var(--space-s) var(--space-s) 0;
}

.nm-footer__social-list-item:last-child {
	margin: 0 0 var(--space-s) 0;
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-footer__navigation-wrap {
		max-width: 1920px;
	}

	.nm-footer__navigation {
		flex-flow: row nowrap;
	}

	.nm-footer__navigation-list {
		flex: 1 0 0%;
	}

	.nm-footer__social-list {
		margin: 0 0 0 var(--space-xxxl);
	}
}
