/* TODO <- this is an interim file - it needs to be cleaned up. Delete it from core-ci after merge. and it needs some love and treatment */

.nm-wrapper,
.nm-header,
.nm-header-inner,
.nm-wrapper-content,
.nm-stage-elements,
.nm-stage,
.nm-animation-wrapper,
.nm-animation-wrapper .nm-animation-inner {
	position: relative;
}

.nm-area-content {
	position: relative;
}

.nm-area-content > .nm-animation-wrapper {
	position: absolute;
	top: 81px;
}

.nm-wrapper {
	margin: 0 auto;
	max-width: 1400px;
	min-width: 980px;
	overflow: hidden;
	transition: max-width .3s linear;
}

nav.navbar + .nm-wrapper {
	min-height: 100%;
}

.nm-wrapper-content .nm-area-content:nth-child(n+2) {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-wrapper-content .nm-area-content:first-child {
	position: relative;
}

.nm-wrapper-content .nm-area-content:nth-child(n+2) > * {
	opacity: 0;
}

@media(max-width: 1400px) {
	.nm-state-is-responsive .nm-wrapper {
		display: inline-block;
	}
}

.nm-state-is-responsive .nm-wrapper {
	min-width: 0;
	width: 100%;
}

/* stage exposition */

.nm-wrapper-content.forward {
	overflow-x: auto;
}

.nm-wrapper-content .nm-area-content .nm-stage-wrapper,
.nm-wrapper-content.backward .nm-area-content:first-child .nm-stage-wrapper,
.nm-wrapper-content.forward .nm-area-content:first-child .nm-stage-wrapper {
	transform: translateX(0);
}

.nm-wrapper-content.backward .nm-area-content:last-child .nm-stage-wrapper {
	transform: translateX(-100%);
}

.nm-wrapper-content.forward .nm-area-content:last-child .nm-stage-wrapper {
	transform: translateX(100%);
}

.nm-wrapper-content.animate .nm-area-content > .nm-stage-wrapper {
	opacity: 1;
}

/* stage animation */

.nm-wrapper-content.animate .nm-area-content .nm-stage-wrapper {
	transition: -webkit-transform .5s;
	transition: transform .5s;
	transition: transform .5s, -webkit-transform .5s;
}

.nm-wrapper-content.animate .nm-area-content .nm-stage-subbg {
	transition: -webkit-transform .5s;
	transition: transform .5s;
	transition: transform .5s, -webkit-transform .5s;
}

.nm-wrapper-content.animate.backward .nm-area-content:first-child .nm-stage-wrapper {
	transform: translateX(100%);
}

.nm-wrapper-content.animate.forward .nm-area-content:first-child .nm-stage-wrapper {
	transform: translateX(-100%);
}

.nm-wrapper-content.animate .nm-area-content:last-child .nm-stage-wrapper {
	transform: translateX(0);
}

.nm-wrapper-content .nm-area-content #nm-page-title {
	display: none;
}

.nm-wrapper-content.animate .nm-area-content .nm-stage-wrapper {
	overflow: visible;
}

/* nav exposition */

.nm-wrapper-content .nm-area-content .nm-nav-wrap {
	visibility: hidden;
}

.nm-wrapper-content .nm-area-content:first-child .nm-nav-wrap {
	overflow: hidden;
	visibility: visible;
}

.nm-wrapper-content .nm-area-content:first-child .nm-nav-wrap.nm-nav-loaded {
	overflow: visible;
}

/* content exposition */

.nm-wrapper-content .nm-area-content .nm-content {
	opacity: 0;
	transition: opacity .5s;
}

.nm-wrapper-content .nm-area-content:first-child .nm-content {
	opacity: 1;
}

/* content animation */

.nm-wrapper-content.animate .nm-area-content:first-child .nm-content {
	opacity: 0;
}

/* masthead */

.nm-header-inner-wrapper {
	background: #fff;
	display: table;
	height: 70px;
	/* min-width: 980px; */
	max-width: 1400px;
	/* gap fix */
	width: 100%;
	/* contains brand mark and navigation */
	z-index: 13;
}

.nm-state-is-responsive .nm-header-inner-wrapper {
	max-width: 1400px;
	min-width: 0;
}

.nm-header-inner {
	margin: 0 auto;
	max-width: 1177.6px;
	/* the inner header container holds the header content in line with the main content */
	width: 92%;
}

.nm-area-content {
	overflow: visible !important;
}

.nm-header + .nm-area-content {
	padding-top: 0;
}

.nm-header + .nm-area-content .nm-content {
	margin: 0 auto;
	max-width: 1177.6px;
	width: 92%;
}

.nm-state-is-responsive .nm-header + .nm-area-content .nm-content {
	max-width: 1177.6px;
	min-width: 0;
	width: 100%;
}

.nm-header + .nm-area-content {
	max-width: 1400px;
	padding-top: 0;
	width: 100%;
}

.nm-header + .nm-area-content .nm-content {
	margin: 0 auto;
	max-width: 1177.6px;
	width: 92%;
}

.nm-state-is-responsive .nm-header + .nm-area-content .nm-content {
	max-width: 1177.6px;
	min-width: 0;
	width: 100%;
}

.nm-content {
	margin: 0 auto;
	max-width: 1177.6px;
	min-height: 0;
	padding-top: 44px;
	pointer-events: none;
	position: relative;
	width: 92%;
}

.nm-content > * {
	pointer-events: all;
}

.nm-state-is-responsive .nm-content {
	max-width: 1177.6px;
	min-width: 0;
}

/* stage */
.nm-stage-elements {
	z-index: 1;
}

/* animation */

.nm-animation-wrapper .nm-animation-inner {
	float: right;
}

.nm-animation-wrapper .nm-animation-inner-stage {
	height: 100%;
}

.nm-animation-hidden {
	visibility: hidden;
}

.nm-animation-wrapper .nm-animation-absolute {
	position: absolute;
}

/*	grid */

/* one column */

.nm-1col {
	width: 26%;
}

.nm-has-basket .nm-1col {
	display: none;
}

/* two columns */
[page-override="widescreen-overwrite"] .nm-2col {
	width: 100%;
}

.nm-2col {
	width: 65%;
}

.nm-has-basket .nm-content .nm-2col,
.nm-has-basket .nm-content .nm-3col,
.nm-is-sticky.nm-has-basket .nm-content .nm-2col,
.nm-is-sticky.nm-has-basket .nm-content .nm-3col {
	margin-bottom: -670px;
}

.nm-not-sticky.nm-has-basket .nm-content .nm-2col,
.nm-not-sticky.nm-has-basket .nm-content .nm-3col {
	margin-bottom: 0;
}

/* three columns */

.nm-3col {
	clear: both;
	/* overflow: hidden;  prevents a horizontal scrollbar in all ie */
	/* removed because of modules with elements out of the parent container e.g. active tiles */
	width: 100%;
}

.nm-3col .nm-module-wrap {
	/* modules within this container will float all left with a margin and won't break */
	margin-left: -9.3%;
	width: 110%;
}

/* oneshop */
[data-template="oneshop"] .nm-3col {
	margin: 0 4%;
	width: 92%;
}

/*	module - grid */

.nm-alpha {
	/* avoids a classname with layout context */
	float: left;
}

.nm-beta {
	/* avoids a classname with layout context */
	float: right;
}

/*	layer - grid */

/* background (shader) */
.nm-form-shader {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNkYGDYz0AEYCJG0ahC6ikEAECXANNoGlngAAAAAElFTkSuQmCC");
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	transition: opacity .25s;
	width: 100%;
	z-index: 100;
}
/* TO DO: Cleanup - this needs to sit in his respective stylesheet - or: Is it obsolete? */

.nm-media-library .nm-price-total,
.nm-media-library .nm-price-single,
.nm-media-library .nm-price {
	display: block;
}

.nm-mediathek-description ul {
	margin-bottom: 20px;
}

.nm-mediathek-description li {
	background: 0 6px no-repeat transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTQwNjM1Q0M0MThDMTFFNEIyNDM5QTg1RTQzNDNDNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTQwNjM1Q0Q0MThDMTFFNEIyNDM5QTg1RTQzNDNDNjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBNDA2MzVDQTQxOEMxMUU0QjI0MzlBODVFNDM0M0M2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBNDA2MzVDQjQxOEMxMUU0QjI0MzlBODVFNDM0M0M2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpdpZfYAAAA9SURBVHjaYmaAAH4gTgFiAyC+C8Q/YYIgzn8ovg/EIiCJHCRBGM5hYsADBLAZxYhkeSwQ/wLilUD8ESDAAC8FEg99M2FqAAAAAElFTkSuQmCC");
	margin-bottom: 7px;
	padding-left: 13px;
}

.nm-mediathek-description p {
	margin-bottom: 20px;
}

@media all and (min-width: 768px) {
	.nm-content {
		min-height: 720px;
	}
}

@media all and (max-width: 1023px) {
	.nm-state-is-responsive .nm-wrapper-content.backward .nm-area-content:last-child .nm-stage-subbg {
		transform: translateX(100%);
	}

	.nm-state-is-responsive .nm-wrapper-content.forward .nm-area-content:last-child .nm-stage-subbg {
		transform: translateX(-100%);
	}

	.nm-wrapper-content.animate.forward .nm-area-content:first-child .nm-stage-subbg {
		transform: translateX(100%);
	}

	.nm-wrapper-content.animate.backward .nm-area-content:first-child .nm-stage-subbg {
		transform: translateX(-100%);
	}

	.nm-wrapper-content.animate.forward .nm-area-content:last-child .nm-stage-subbg {
		transform: translateX(0);
	}

	.nm-wrapper-content.animate.backward .nm-area-content:last-child .nm-stage-subbg {
		transform: translateX(0);
	}
}

@media all and (max-width: 1023px) {
	.nm-state-is-responsive .nm-header-inner {
		left: 4%;
		position: absolute;
		top: 0;
	}
}

@media all and (max-width: 1099px) {
	.nm-state-is-responsive .nm-1col,
	.nm-state-is-responsive .nm-2col {
		width: 100%;
	}
}
