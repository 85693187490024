.nm-anchor-navigation {
	margin: 0 0 var(--space-xxl);
	overflow: auto;
	width: 100%;
}

.nm-anchor-navigation:empty {
	margin-bottom: 0;
}

.nm-anchor-navigation--no-headline {
	margin-top: var(--space-xxl);
}

.nm-anchor-navigation--separator {
	border-bottom: 1px solid #e5e5e5;
}

.nm-anchor-navigation__headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xs);
}

[page-type="widescreen"] .nm-anchor-navigation__headline {
	margin-left: var(--space-column);
	margin-right: var(--space-column);
}

[page-type="widescreen"] .nm-3col .nm-anchor-navigation__headline {
	margin-left: var(--space-side-margin);
	margin-right: var(--space-side-margin);
}
