/*	=========================================================================
	Header - Search

	nm-header-is-half-way-special = scope fareast
========================================================================= */

.nm-navigation-header-search {
	height: 16px;
	min-width: 16px;
	/* relevant for open flyout */
	position: relative;
	width: auto;
	z-index: 100;
}

.nm-navigation-header-search .nm-navigation-header-link {
	align-items: center;
	display: inline-flex;
	height: 16px; /* a height prevents the loupe from jumping around when text is hidden */
	justify-content: flex-start;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
}

.nm-navigation-header-search .nm-label-search {
	font-size: 1.4rem;
	margin-left: 5px;
	pointer-events: none;
	visibility: visible;
	white-space: nowrap;
}

.nm-navigation-header-search .nm-icon-search-small {
	display: inline-block;
	fill: currentColor;
	height: 1.6rem;
	stroke: currentColor;
	stroke-width: 0;
	width: 1.6rem;
}

.nm-navigation-header-search.nm-header-overlay-is-open .nm-navigation-header-overlay {
	height: 110px;
}

.nm-navigation-header-search .nm-navigation-header-detail-container {
	margin-top: 40px;
	max-width: none;
	min-width: 0;
	width: 100%;
}

.nm-header-is-half-way-special .nm-header-search-container {
	display: inline-block;
	padding-top: 6px; /* padding is according to other menuelements */
	position: relative;
	top: 65px;
}

@media screen and (max-width: 1187px) {
	.nm-navigation-header-search .nm-label-search {
		display: none;
	}

	.nm-header-is-half-way-special .nm-navigation-header-search .nm-label-search {
		display: block;
	}
}

@media screen and (max-width: 1110px) {
	.nm-navigation-header-search .nm-navigation-header-link {
		margin-right: 0;
		width: auto;
	}

	.nm-navigation-header-search .nm-label-search {
		display: inline-block;
		font-size: 1.2rem;
	}

	.nm-header-is-half-way-special .nm-navigation-header-search .nm-label-search {
		font-size: 1.4rem;
	}
}

@media all and (min-width: 1000px) {
	.nm-header-is-half-way-special .nm-navigation-header-search .nm-label-search {
		font-size: 1.4rem;
	}
}

@media all and (max-width: 999px) {
	.nm-navigation-header-search .nm-label-search {
		display: inline-block;
		font-size: 1.2rem;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-search .nm-navigation-header-link {
		font-size: 1.2rem;
		height: 20px;
		margin-left: 20px;
		margin-right: 0;
		width: auto;
	}

	.nm-navigation-is-dropdown .nm-navigation-header-search {
		float: left;
		height: 20px;
		margin-top: 8px;
		max-width: 132px;
		min-width: 50px;
		width: auto;
	}

	.nm-header-is-half-way-special .nm-navigation-header-search .nm-navigation-header-link {
		padding-top: 2px;
	}

	.nm-header-is-half-way-special .nm-navigation-header-search .nm-label-search {
		font-size: 1.2rem;
	}

	.nm-header-is-half-way-special .nm-navigation-header-search {
		margin-top: 0;
	}

	.nm-header-is-half-way-special .nm-header-search-container {
		padding-top: 0; /* overwrites default padding for wider viewports */
		top: 52px;
	}
}

@media screen and (max-width: 899px) {
	.nm-navigation-header-search {
		width: auto;
	}

	.nm-navigation-header-search .nm-navigation-header-link {
		font-size: 1.2rem;
		margin-right: 0;
		width: auto;
	}
}

@media screen and (max-width: 435px) {
	.nm-navigation-header-search .nm-label-search {
		display: none;
	}

	.nm-header-is-half-way-special .nm-navigation-header-search .nm-label-search {
		display: block;
	}
}
