/*	=========================================================================
	Header - Navigation
		covers all declarations regarding first navigation layer
		dropdown variation => header.navigation.dropdown.css
		overlay and contents => header.overlay.css
========================================================================= */

/* wrapper and scope */
.nm-navigation-header {
	height: 70px;
	max-width: 1400px;
	overflow: hidden;
	position: relative;
	width: auto;
}

.nm-navigation-header .nm-navigation-header-item,
.nm-navigation-header .nm-navigation-header-static-item {
	float: left;
	width: auto;
}

.nm-navigation-header .nm-navigation-header-link {
	margin-left: 7px;
	margin-right: 7px;
	padding: 25px 0;
	position: relative; /* positioning and z-index is for overlay open state */
	z-index: 100;
}

.nm-navigation-header .nm-navigation-header-sitemap .nm-navigation-header-link {
	align-items: center;
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	margin: 0 25px 0 0;
	padding: 19px 0;
}

.nm-navigation-header .nm-icon-off-canvas-large {
	display: inline-block;
	fill: currentColor;
	height: 3.2rem;
	stroke: currentColor;
	stroke-width: 1;
	width: 3.2rem;
}

.nm-navigation-header .nm-navigation-header-mofi-link {
	margin: 0 40px 0 0;
}

.nm-header-overlay-is-open .nm-navigation-header-link,
.nm-navigation-header-link:hover {
	color: #4c4c4c;
}

/* ie. r8, rs */
.nm-navigation-sitemap-linklist-item .nm-navigation-header-link-rhombus::before,
.nm-navigation-header-dropdown-item .nm-navigation-header-link-rhombus::before,
.nm-navigation-header-link.nm-navigation-header-link-rhombus::before,
.nm-link-basic .nm-navigation-header-link-rhombus::before {
	background-color: var(--color-progressive-red);
	content: "";
	display: inline-block;
	height: 11px;
	margin-left: 3px; /* should fix the overlapping in dropdown states */
	margin-right: 6px;
	transform: skew(-30deg);
	width: 12px;
}

.nm-navigation-header .nm-header-nav-items {
	display: inline-block;
	float: none;
	margin-right: 10px;
}

/* navigation header with two rows */
.nm-header-is-half-way-special .nm-navigation-header {
	top: 64px;
}

.nm-header-is-half-way-special .nm-navigation-header .nm-navigation-header-link {
	padding-top: 6px;
}

@media screen and (max-width: 1110px) {
	.nm-navigation-header {
		height: 50px;
	}

	.nm-navigation-header .nm-navigation-header-sitemap .nm-navigation-header-link {
		font-size: 1.2rem;
		padding-top: 9px;
	}

	.nm-navigation-header .nm-header-nav-items {
		display: none;
	}

	.nm-navigation-header .nm-navigation-header-mofi-link {
		font-size: 1.2rem;
		padding: 17px 0 0;
	}
}

@media all and (max-width: 999px) {
	.nm-navigation-header {
		position: static;
	}

	.nm-header-is-half-way-special .nm-navigation-header {
		margin-top: 34px;
	}
}

@media all and (max-width: 899px) {
	.nm-navigation-header .nm-navigation-header-mofi-link {
		padding: 20px 0 0;
	}
}

@media screen and (max-width: 749px) {
	.nm-navigation-header {
		max-width: 92%;
		width: auto;
	}
}
