/* mediaplayer */
.nm-multi-media {
	margin: 75px 0 25px;
	position: relative;
	z-index: 0;
}

.nm-multi-media + p {
	padding: 20px 0 0;
}

.nm-multi-media + .nm-cp-text > .nm-c2-b {
	margin-bottom: 70px;
}

.nm-mediaplayer {
	height: 0;
	line-height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-layer-image .nm-mediaplayer {
	background: #000;
	padding-bottom: 42%;
}

.nm-mediathek-image .nm-mediaplayer {
	padding-bottom: 56%;
}

.nm-mediaplayer {
	position: relative;
}

.nm-mediaplayer video,
.nm-mediaplayer .me-plugin {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100% !important;
}

.nm-stage-media .me-plugin embed,
.nm-stage-media .me-plugin object {
	margin-top: -47px;
}

.nm-stage-media .nm-mediaplayer {
	height: 100%;
	left: 0;
	padding-bottom: 0;
	position: absolute;
	text-align: center;
	top: 0;
}

.nm-stage-media .me-plugin,
.nm-stage-media video {
	position: absolute;
	top: 0;
}

.nm-stage-media video {
	width: 100%;
}

.nm-stage-media .me-plugin {
	height: 100%;
	width: 100%;
}

.nm-mediaplayer video {
	pointer-events: none;
}

.nm-prevent-video-click {
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}

.nm-mediaplayer .nm-button-overlay {
	height: 0;
	left: 0;
	margin: 0;
	overflow: visible;
	position: absolute;
	top: -webkit-calc(50% - 35px);
	top: calc(50% - 35px);
	width: 100%;
}

.nm-mediaplayer .nm-controls-wrap,
.nm-mediaplayer .nm-controls-wrap-video {
	background-color: rgba(0, 0, 0, .75);
	bottom: -81px;
	height: 80px;
	position: absolute;
	transition: all .15s ease-in-out;
	width: 100%;
}

.nm-mediaplayer .nm-controls-wrap.nm-show-controls {
	bottom: 0;
}

.nm-mediaplayer:hover:not(.active-fs) .nm-controls-wrap,
.nm-mediaplayer:hover:not(.active-fs) .nm-controls-wrap-video {
	bottom: 0;
}

.nm-mediaplayer .nm-el-lbl {
	margin-bottom: 0;
}

.nm-controls-wrap,
.nm-controls-wrap-video {
	z-index: 15;
}

.nm-controls-wrap .nm-video-loader,
.nm-controls-wrap-video .nm-video-loader {
	background: #6d7579;
	height: 8px;
	position: relative;
	width: 100%;
}

.nm-video-loader .nm-loader-played,
.nm-video-loader .nm-loader-loaded {
	height: 8px;
	left: 0;
	position: absolute;
	top: 0;
	width: 0;
}

.nm-controls-wrap .nm-controls,
.nm-controls-wrap-video .nm-controls-video {
	height: 72px;
	position: relative;
	width: 100%;
}

.nm-video-loader .nm-loader-played {
	background: var(--color-progressive-red);
	width: 0;
	z-index: 2;
}

.nm-video-loader .nm-loader-loaded {
	background: #fff;
	width: 0;
	z-index: 1;
}

.nm-video-loader:hover {
	cursor: pointer;
}

.nm-controls button,
.nm-controls-video button {
	background: transparent;
	border: none;
	cursor: pointer;
	display: block;
	outline: none;
}

.nm-button-play-pause {
	height: 72px;
	left: 0;
	position: absolute;
	width: 79px;
}

.nm-button-play-pause button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-play.svg");
	background-position: center center;
	background-repeat: no-repeat;
	height: 32px;
	margin: 20px 24px;
	width: 32px;
}

.nm-button-play-pause.nm-pause button,
.nm-button-play-pause button.nm-video-button-pause {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-pause.svg");
}

.nm-button-play-pause button:hover {
	filter: saturate(0) brightness(1000%);
}

/* stylelint-disable */
/* IE11 Only */
_:-ms-fullscreen, :root .nm-button-play-pause.nm-play button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-play.svg");
}

_:-ms-fullscreen, :root .nm-button-play-pause.nm-play button:hover {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-play_white.svg");
}

_:-ms-fullscreen, :root .nm-button-play-pause.nm-pause button,
_:-ms-fullscreen, :root .nm-button-play-pause button.nm-video-button-pause {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-pause.svg");
}

_:-ms-fullscreen, :root .nm-button-play-pause.nm-pause button:hover,
_:-ms-fullscreen, :root .nm-button-play-pause button.nm-video-button-pause:hover {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-pause_white.svg");
}
/* stylelint-enable */

.nm-title-wrap {
	font-family: AudiTypeWide;
	height: 72px;
	left: 79px;
	overflow: hidden;
	padding-right: 116px;
	position: absolute;
}

.nm-title-wrap span {
	line-height: 1em;
}

.nm-title span {
	display: block;
	padding: 21px 0 0;
}

.nm-time span {
	color: #666;
	display: inline-block;
	padding: 10px 0 0;
}

.nm-time .nm-time-played {
	color: #fff;
}

.nm-time .nm-time-break {
	padding: 0 5px;
}

.nm-button-mute,
.nm-button-fullscreen {
	float: right;
	height: 72px;
	margin: 0 18px 0 0;
	width: 32px;
}

.nm-button-mute {
	margin-right: 10px;
}

.nm-button-mute button,
.nm-button-fullscreen button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-audio-on.svg");
	background-position: center center;
	background-repeat: no-repeat;
	height: 32px;
	margin: 20px 0;
	width: 32px;
}

.nm-button-fullscreen button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-increase.svg");
}

.nm-button-mute.nm-unmute button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-audio-off.svg");
}

.nm-button-fullscreen.nm-minimize button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-decrease.svg");
}

.nm-button-mute button:hover,
.nm-button-fullscreen button:hover {
	filter: saturate(0) brightness(1000%);
}

/* stylelint-disable */
/* IE11 Only */
_:-ms-fullscreen, :root .nm-button-mute.nm-unmute button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-audio-off.svg");
}

_:-ms-fullscreen, :root .nm-button-mute.nm-unmute button:hover {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-audio-off_white.svg");
}

_:-ms-fullscreen, :root .nm-button-mute.nm-mute button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-audio-on.svg");
}

_:-ms-fullscreen, :root .nm-button-mute.nm-mute button:hover {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-audio-on_white.svg");
}

_:-ms-fullscreen, :root .nm-button-fullscreen button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-increase.svg");
}

_:-ms-fullscreen, :root .nm-button-fullscreen button:hover {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-increase_white.svg");
}

_:-ms-fullscreen, :root .nm-button-fullscreen.nm-minimize button {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-decrease.svg");
}

_:-ms-fullscreen, :root .nm-button-fullscreen.nm-minimize button:hover {
	background-image: url("../assets/icons/svg/mediaplayer-legacy/mp-decrease_white.svg");
}
/* stylelint-enable */

.nm-mediaplayer-navigation {
	height: 131px;
	position: absolute;
	text-align: center;
	top: 40.16%;
	width: 100%;
}

.nm-mediaplayer-navigation li {
	display: inline-block;
	height: 113px;
	width: 126px;
}

.nm-mediaplayer-navigation li:first-child {
	margin-right: 20px;
}

.nm-mediaplayer-navigation .nm-c9 {
	display: block;
	margin-top: 88px;
}

/* For new Video Player */
.nm-overlay-hide {
	display: none;
}

.nm-video.nm-hide-controls .nm-prevent-video-click,
.nm-video.nm-hide-controls .nm-controls-wrap-video,
.nm-video.nm-hide-controls .nm-j-play-btn {
	display: none;
}

.nm-video.nm-hide-controls video {
	pointer-events: inherit;
}

.nm-video:not(.nm-hide-controls) video::-webkit-media-controls {
	display: none !important;
}

.nm-video:not(.nm-hide-controls) video::-webkit-media-controls-enclosure {
	display: none !important;
}

.nm-controls-wrap-video-full {
	bottom: 0 !important;
	left: 0;
	right: 0;
}

.nm-mediaplayer.ie-brwsr .nm-button-fullscreen {
	display: none;
}
