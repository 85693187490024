/*********************************************************
	Support Bar (mobile first)
*********************************************************/

.nm-footer__support-bar-wrap {
	box-sizing: border-box;
	margin: 40px auto 0;
	max-width: 1177.6px;
	min-width: 0;
	padding: 30px 0 calc(var(--space-xxl) - 20px);
	width: 92%;
}

.nm-footer__support-bar {
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.nm-footer__support-bar-item {
	box-sizing: border-box;
	padding: 0 var(--space-xl);
	position: relative;
	text-align: center;
	vertical-align: top;
	width: 50%;
}

.nm-footer__support-bar-link:hover .nm-footer__support-bar-linktext {
	color: #d0d3d4;
}

/* divider line */
.nm-footer__support-bar-item::after {
	background-color: #6d7579;
	content: " ";
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
}

.nm-footer__support-bar::after {
	background-color: var(--color-grey-70); /* overwrites the divider line for the last element in the support bar */
	content: " ";
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 3px;
}

.nm-footer__support-bar-item:last-child::after {
	content: none;
}

.nm-footer__support-bar-link {
	display: block;
	height: auto;
	padding-left: 0;
}

.nm-footer__support-bar-link::before {
	background-size: 48px 48px;
	content: "";
	display: block;
	height: 48px;
	margin: 0 auto;
	position: relative;
	width: 48px;
}

.nm-footer__support-bar-link::before,
.nm-footer__support-bar-link:hover::before {
	background-color: transparent;
}

.nm-footer__support-bar-linktext {
	display: block;
	margin: var(--space-s) 0 0;
	word-wrap: break-word;
}

/* Light BG used for asian market ie. china */
.nm-footer .nm-footer-light-bg .nm-footer__support-bar-wrap {
	margin-top: 0; /* preventing gap */
}

.nm-footer .nm-footer-light-bg,
.nm-footer .nm-footer-light-bg .nm-footer__support-bar::after {
	background-color: #f2f2f2;
}

.nm-footer .nm-footer-light-bg .nm-footer__support-bar {
	border-top: 1px solid #d0d3d4;
	padding: 20px 0;
}

.nm-footer__support-bar-item::after {
	background-color: #d0d3d4;
}

.nm-footer .nm-footer-light-bg .nm-footer__support-bar-linktext {
	color: #000;
}

.nm-footer .nm-footer-light-bg .nm-footer__support-bar-link:hover .nm-footer__support-bar-linktext {
	color: #4c4c4c;
}

@media all and (max-width: 767px) {
	.nm-footer__support-bar-numitems-3 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-3 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-4 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-4 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(4),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(4),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(4),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(5),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(6),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(4),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(5),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(6) {
		margin-bottom: var(--space-xl);
	}
}

@media all and (min-width: 768px) {
	.nm-footer__support-bar-item {
		width: 33.3333%;
	}

	.nm-footer__support-bar-numitems-4 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-4 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-4 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(4),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(5),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(6),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(1),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(2),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(3),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(4),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(5),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(6) {
		margin-bottom: var(--space-xl);
	}
}

@media all and (min-width: 1024px) {
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item,
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item {
		width: 25%;
	}

	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(5),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(6),
	.nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item:nth-child(7),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(5),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(6),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(7),
	.nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item:nth-child(8) {
		margin-bottom: 0;
	}
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-footer__support-bar-wrap {
		max-width: 1920px;
	}

	[page-type=widescreen] .nm-footer__support-bar-item {
		width: 16.6666%;
	}

	[page-type=widescreen] .nm-footer__support-bar-numitems-7 .nm-footer__support-bar-item,
	[page-type=widescreen] .nm-footer__support-bar-numitems-8 .nm-footer__support-bar-item {
		width: 25%;
	}

	[page-type=widescreen] .nm-footer__support-bar-numitems-1 .nm-footer__support-bar-item,
	[page-type=widescreen] .nm-footer__support-bar-numitems-2 .nm-footer__support-bar-item,
	[page-type=widescreen] .nm-footer__support-bar-numitems-3 .nm-footer__support-bar-item,
	[page-type=widescreen] .nm-footer__support-bar-numitems-4 .nm-footer__support-bar-item,
	[page-type=widescreen] .nm-footer__support-bar-numitems-5 .nm-footer__support-bar-item,
	[page-type=widescreen] .nm-footer__support-bar-numitems-6 .nm-footer__support-bar-item {
		margin-bottom: 0;
	}
}

/* support-bar dark-theme legacy */
.nm-footer__support-bar[data-theme=dark] .audi-copy-s {
	color: var(--color-white);
}
