/*	stage controls
============================================================================= */

/* navigate stage images - browsing back and forth  */

.nm-bs {
	display: none;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 2;
}

.nm-bs-remote {
	height: 1px;
	left: 0;
	margin-top: 15.5%;
	position: absolute;
	width: 100%;
	z-index: 10;
}

@media screen and (max-width: 1023px) {
	.nm-bs-remote {
		margin-top: calc(15.5% + 20px);
	}
}

@media screen and (max-aspect-ratio: 1/1) {
	.nm-bs-remote.nm-stage-has-mobileimg {
		margin-top: 28%;
	}
}

@media screen and (max-aspect-ratio: 1/1) and (max-width: 1023px) {
	.nm-bs-remote.nm-stage-has-mobileimg {
		margin-top: calc(28% + 20px);
	}
}

.nm-bs-remote .nm-stage-browse {
	height: 100%;
}

.nm-stage-browse a {
	cursor: pointer;
	display: block;
	float: right;
	height: 40px;
	margin-top: -15px;
	width: 35px;
}

.nm-stage-browse .nm-icon-back-arrow::before {
	background-position: center right;
	width: 35px;
}

.nm-stage-browse .nm-backarrow a {
	float: left;
}