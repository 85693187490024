/* ==========================================================================
	cookie-policy
============================================================================= */

.nm-cookiepolicy {
	background-color: #4c4c4c;
	width: 100%;
}

.nm-cookiepolicy-inner {
	margin: 0 auto;
	padding-bottom: 13px;
	padding-top: 16px;
	width: 92%;
}

.nm-cookiepolicy-inner ul {
	clear: both;
	display: flex;
	flex-flow: row;
	justify-content: flex-end;
	margin-bottom: 0;
	margin-top: 15px;
	position: relative;
	width: 100%;
}

.nm-cookiepolicy-inner li {
	margin-right: 20px;
}

.nm-cookiepolicy-inner li:last-child {
	margin-right: 0;
}

.nm-cookiepolicy-inner .continue {
	text-decoration: none !important;
}

@media screen and (min-width: 1280px) {
	.nm-cookiepolicy-inner {
		max-width: 1177.6px;
		width: 100%;
	}
}
