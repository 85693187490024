/*********************************************************
	Support Bar - Inpage Navigation (mobile first)
	Nota: Styles for the Support-Bar-Flyout. For the
	togglelabel refer to main navigation
*********************************************************/

/* --- The Flyout --- */
.nm-supportbar-navigation {
	background-color: #fff;
	box-sizing: border-box;
	height: 0;
	overflow: hidden;
	position: absolute;
	width: 100%;
	z-index: 10;
}

.nm-nav-fixed .nm-supportbar-navigation {
	max-width: 1400px;
	position: fixed;
	top: 50px;
}

/* support bar items */
.nm-supportbar-navigation .nm-supportbar-navigation-list li {
	box-sizing: border-box;
	display: inline-block;
	min-width: 130px;
	padding: 0 20px;
	position: relative;
	text-align: center;
	vertical-align: top;
}

.nm-supportbar-navigation .nm-supportbar-navigation-list a:hover .nm-supportbar-navigation-linktext {
	color: #4c4c4c;
}

/* divider line */
.nm-supportbar-navigation .nm-supportbar-navigation-list li::after {
	background: #d0d3d4;
	content: "";
	height: 40px;
	position: absolute;
	right: 0;
	top: calc(50% - 20px);
	width: 1px;
}

/* overwrites the divider line for the last element in the support bar */
.nm-supportbar-navigation .nm-supportbar-navigation-list::after {
	background-color: #fff;
	content: " ";
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 3px;
}

.nm-supportbar-navigation .nm-supportbar-navigation-list .nm-link-basic::before {
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 60px;
	margin: 0 auto;
	position: relative;
	vertical-align: middle;
	width: 60px;
}

.nm-supportbar-navigation .nm-supportbar-navigation-list a.nm-el-lk-icn {
	display: block !important;
	height: auto;
	padding-left: 0;
}

.nm-supportbar-navigation .nm-supportbar-navigation-list li a.nm-el-lk-icn::before {
	display: block;
	height: 64px;
	position: relative;
	width: 64px;
}

.nm-supportbar-navigation .nm-supportbar-navigation-list a.nm-el-lk-icn::before,
.nm-supportbar-navigation .nm-supportbar-navigation-list a.nm-el-lk-icn:hover::before {
	background-color: transparent;
}

.nm-supportbar-navigation .nm-supportbar-navigation-list .nm-supportbar-navigation-linktext {
	display: block;
	margin: 8px 0 20px;
	word-wrap: break-word;
}

/* this overwrites styles from old basic elements and can be deleted after complete switch to new ci */
.nm-supportbar-navigation .nm-supportbar-navigation-list .nm-el-lk.nm-link-basic {
	font-family: AudiTypeWide, sans-serif;
}

.nm-supportbar-navigation .nm-supportbar-navigation-numitems-8 li,
.nm-supportbar-navigation .nm-supportbar-navigation-numitems-7 li,
.nm-supportbar-navigation .nm-supportbar-navigation-numitems-6 li,
.nm-supportbar-navigation .nm-supportbar-navigation-numitems-5 li,
.nm-supportbar-navigation .nm-supportbar-navigation-numitems-4 li,
.nm-supportbar-navigation .nm-supportbar-navigation-numitems-3 li {
	min-width: 50%;
	width: 50%;
}

@media all and (min-width: 440px) {
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-8 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-7 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-6 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-5 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-4 li {
		min-width: 33.3333%;
		width: 33.3333%;
	}
}

@media all and (min-width: 581px) {
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-8 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-7 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-6 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-5 li {
		min-width: 25%;
		width: 25%;
	}
}

@media all and (min-width: 847px) {
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-8 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-7 li,
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-6 li {
		min-width: 16.6666%;
		width: 16.6666%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-5 li {
		min-width: 20%;
		width: 20%;
	}
}

@media all and (min-width: 1000px) {
	.nm-supportbar-navigation .nm-supportbar-navigation-list::after {
		right: 40px;
	}
}

@media all and (min-width: 1145px) {
	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-1 li {
		width: 100%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-2 li {
		width: 50%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-3 li {
		width: 33.33%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-4 li {
		width: 25%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-5 li {
		width: 20%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-6 li {
		width: 16.66%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-7 li {
		width: 14.28%;
	}

	.nm-supportbar-navigation .nm-supportbar-navigation-numitems-8 li {
		width: 12.5%;
	}
}
