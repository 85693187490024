/*	=========================================================================
	Header - Logo
	========================================================================= */

/* note: the css scope nm-is-half-way-special can be used to cater the markets with dropdowns, ie cn, ca */
/* note: basic logo styles => basic.generic.css */

.nm-header-search-logo {
	height: 70px;
}

/* logo in markets with additional logo */
.nm-header-is-half-way-special .nm-header-logo-container {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
}

/* container for faw logo */
.nm-header-is-half-way-special .nm-header-additional-logo-container {
	height: 26px;
	left: 0;
	position: absolute;
	top: 20px;
	width: 100px;
	z-index: 100;
}

.nm-header-is-half-way-special .nm-additional-logo {
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: auto 100%;
	display: block;
	height: 26px;
	margin: 0 0 0 12px !important;
	padding: 0 !important;
	width: 100px;
}

.nm-header-is-half-way-special .nm-additional-logo-text {
	display: none;
}

@media all and (max-width: 1130px) {
	.nm-header-search-logo {
		max-width: none;
		min-width: 0;
	}
}

@media screen and (max-width: 1110px) {
	.nm-logo {
		margin-left: 0;
		width: auto;
	}

	.nm-header-search-logo {
		height: 50px;
	}
}

@media all and (max-width: 999px) {
	.nm-header-is-half-way-special .nm-header-additional-logo-container {
		top: 10px;
	}

	.nm-header-is-half-way-special .nm-additional-logo {
		margin: 0;
	}
}

@media screen and (max-width: 899px) {
	.nm-header-search-logo {
		padding-left: 0;
		width: auto;
	}

	.nm-logo {
		margin-left: 30px;
	}
}