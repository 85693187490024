
/*	basic typography
============================================================================= */

.nm-state-is-responsive body {
	/* base body class for switching */
	font-size: 1.2rem;
}

/* headlines */

/*	nm = namespace
	at = atom
	el = element
	md = module
*/

/* base class */

.nm-el-hdl {
	display: block;
	/* minimum space - was 20px */
	font-family: AudiTypeExtended, Verdana, sans-serif;
	margin-bottom: 10px;
}

.nm-el-hdl a {
	color: #000;
}

.nm-el-hdl a:hover {
	color: var(--color-progressive-red);
}

/* atom: on dark background */

.nm-at-hdl-inv,
.nm-at-hdl-inv a {
	color: #fff;
}

.nm-at-hdl-inv a:hover {
	color: rgba(255, 255, 255, .7);
}

/* atom: font weight */

.nm-at-hdl-b {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

/*
.nm-el-hdl-01 = HLW
.nm-el-hdl-02 = HLM
.nm-el-hdl-03 = HL, HL-R
.nm-el-hdl-04 = HLT, HLT-R
.nm-el-hdl-05 = HLS, HLS-R
*/

/* HLW */

.nm-el-hdl-01 {
	font-size: 3.6rem;
	line-height: 4rem;
	margin: 0 0 19px;
}

/* HLM */

.nm-el-hdl-02 {
	font-size: 2.6rem;
	line-height: 3.6rem;
	margin: 0 0 14px;
}

/* HL, HL-R */

.nm-el-hdl-03 {
	/* defined 30px - font specific spacing */
	font-size: 1.8rem;
	line-height: 3rem;
	margin-bottom: 20px;
}

/* HLT, HLT-R */

.nm-el-hdl-04 {
	font-size: 1.5rem;
	line-height: 2rem;
}

/* HLS, HLS-R */

.nm-el-hdl-05 {
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.nm-el-hdl-02 + .nm-el-hdl-03 {
	margin-top: -12px;
}

.nm-el-hdl-02 + .nm-el-hdl-04 {
	margin-top: -8px;
}

.nm-el-hdl-02 + .nm-el-hdl-05 {
	margin-top: -8px;
}

/* paragraphs */

/* base class */

.nm-el-pg {
	/* margin-bottom: 21px; margins for paragraphs are set when needed */
	font-family: Verdana, sans-serif;
}

.nm-at-pg-b {
	font-weight: 700;
}

.nm-el-pg a {
	color: #000;
	text-decoration: underline;
}

.nm-el-pg a:hover {
	color: var(--color-progressive-red);
}

/* on dark background */

.nm-at-pg-inv,
.nm-at-pg-inv a {
	color: #fff;
}

.nm-at-pg-inv a:hover {
	color: rgba(255, 255, 255, .7);
}

.nm-at-pg-indicator,
.nm-at-pg-indicator a {
	color: var(--color-progressive-red);
}

.nm-at-pg-variant,
.nm-at-pg-variant a {
	color: #6d7579;
}

.nm-at-pg-variant.nm-at-pg-inv,
.nm-at-pg-variant.nm-at-pg-inv {
	color: #b0b6b8;
}

/*
.nm-el-pg-01 = TXT-L
.nm-el-pg-02 = TXT-T
.nm-el-pg-03 = TXT, TXT-B, TXT-N, TXT-G
.nm-el-pg-04 = TXT-LST, TXT-LST-B, TXT-LST-N, TXT-LST-G
.nm-el-pg-05 = FN-TXT
*/

/* TXT-L */

.nm-el-pg-01 {
	font-size: 1.8rem;
	line-height: 3rem;
}

/* TXT-T */

.nm-el-pg-02 {
	font-family: Verdana, sans-serif;
	font-size: 1.3rem;
}

/* TXT, TXT-B, TXT-N, TXT-G */

/* TXT-LST */

/* TXT-LST-B */

/* TXT-LST-N */

/* TXT-LST-G */

.nm-el-pg-03,
.nm-el-pg-04 {
	font-size: 1.2rem;
}

/* TXT-T */

/* TXT, TXT-B, TXT-N, TXT-G */

.nm-el-pg-02,
.nm-el-pg-03 {
	line-height: 2.2rem;
}

/* TXT-LST, TXT-LST-B, TXT-LST-N, TXT-LST-G */

.nm-el-pg-04 {
	line-height: 1.8rem;
}

/* TXT-FN */

.nm-el-pg-05 {
	font-size: .75em;
	line-height: 1.1rem;
}

/* labels - short texts, definitions */

/*	.nm-el-lbl-01 = L-INFO, L-INFO-B
	.nm-el-lbl-02 = L-TXT
	.nm-el-lbl-03 = L-TXT-B
	.nm-el-lbl-04 = L-TXT-W
	.nm-el-lbl-05 = L-FN
*/

/* base class */

.nm-el-lbl {
	color: #000;
	font-family: Verdana, sans-serif;
	margin-bottom: 20px;
}

.nm-el-lbl a {
	color: #000;
}

.nm-el-lbl a:hover {
	color: var(--color-progressive-red);
}

.nm-at-lbl-b {
	font-weight: 700;
}

/* on dark background */

.nm-at-lbl-inv,
.nm-at-lbl-inv a {
	color: #fff;
}

.nm-at-lbl-inv a:hover {
	color: rgba(255, 255, 255, .7);
}

.nm-el-lbl-02,
.nm-el-lbl-04 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
}

/* L-TXT-B */

.nm-el-lbl-03 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

/* L-FN */

/* use with sup or sub - basic.generic.css */

.nm-at-lbl-variant {
	color: #6d7579;
	margin-bottom: 0;
}

.nm-at-lbl-inv.nm-at-lbl-variant {
	color: rgba(176, 182, 184, 1);
}

.nm-el-lbl-01,
.nm-el-lbl-02,
.nm-el-lbl-03 {
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* L-TXT-W   */

.nm-el-lbl-04 {
	font-size: 3.6rem;
	line-height: 4rem;
}

/* L-FN */

.nm-el-lbl-05 {
	color: #6d7579;
	font-size: 1.1rem;
	line-height: 1.5rem;
	margin-bottom: 0;
}

/* links */

/*	.nm-el-lk-01 = BA-LNK, BA-LNK-R
	.nm-el-lk-02 = TXT-LNK, TXT-LNK-G
	.nm-el-lk-03 = N-LNK
	.nm-el-lk-04 = N-LNK-R
	.nm-el-lk-05 = HL-LNK
	.nm-el-lk-06 = CTA-LNK
	.nm-el-lk-07 = CTA-LNK-S
	.nm-el-lk-08 = N-LNK Breadcrumb with arrow
	.nm-el-lk-09 = N-LNK Breadcrumb with double arrow
	.nm-el-lk-10 = FN_LNK
*/

/* base class */

.nm-el-lk {
	color: #000;
	/* margin-top: 17px; <-- not working */
	cursor: pointer;
	font-family: Verdana, sans-serif;
	position: relative;
}

.nm-at-lk-b {
	font-weight: 700;
}

.nm-el-lk:hover {
	color: var(--color-progressive-red);
}

/* basic style before */

.nm-el-lk::before {
	content: "";
	display: inline-block;
	vertical-align: middle;
}

/* basic style with assets (arrow) */

.nm-el-lk.nm-el-lk-ast::before {
	height: 15px;
	left: 0;
	position: absolute;
	top: 0;
	width: 7px;
}

.nm-el-lk.nm-el-lk-ast {
	display: inline-block;
	padding-left: 12px;
}

/* asset icon basics */

.nm-el-lk.nm-el-lk-icn::before {
	background-color: #000;
	background-position: center center;
	background-repeat: no-repeat;
	height: 32px;
	left: 0;
	position: absolute;
	top: 0;
	width: 32px;
}

.nm-el-lk.nm-el-lk-icn.nm-at-lk-highlight::before,
.nm-el-lk.nm-el-lk-icn.nm-at-lk-variant::before {
	background-color: #e7e9ea;
}

.nm-el-lk.nm-el-lk-icn.nm-at-lk-transparent::before {
	background-color: transparent;
}

/* icon bg is changing colors by hovering the links */

.nm-el-lk.nm-el-lk-icn:hover::before {
	background-color: rgba(0, 0, 0, .7);
}

.nm-el-lk.nm-el-lk-icn.nm-at-lk-transparent:hover::before {
	background-color: transparent;
}

.nm-el-lk.nm-el-lk-icn.nm-at-lk-variant:hover::before {
	background-color: #d0d3d4;
}

.nm-el-lk.nm-el-lk-icn.nm-at-lk-highlight:hover::before {
	background-color: #424a52;
}

.nm-el-lk.nm-el-lk-icn {
	display: table-cell !important;
	height: 32px;
	padding-left: 40px;
	vertical-align: middle;
}

/* on dark background */

.nm-at-lk-inv,
.nm-at-lk-inv {
	color: #fff;
}

.nm-at-lk-inv:hover {
	color: rgba(255, 255, 255, .7);
}

/* indicator is for notes and errors */

.nm-at-pg-indicator,
.nm-at-pg-indicator a {
	color: #c03;
}

/* variants are for disclaimer thingies */

.nm-at-pg-variant,
.nm-at-pg-variant a {
	color: #6d7579;
}

.nm-at-pg-variant.nm-at-pg-inv,
.nm-at-pg-variant.nm-at-pg-inv {
	color: #b0b6b8;
}

/* BA-LNK (A1 basic link) */

.nm-el-lk-ast::before {
	/* arrow red */
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
}

.nm-at-lk-inv.nm-el-lk-ast::before {
	/* arrow white */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjQ2QjU2RjNGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjQ2QjU2RjRGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGMUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGMkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PldVk/MAAABNSURBVHjaYvj//78AEL//DwEBQMwAwzCGAVTyPZSNIgnCCegKkCVBeD5UwX2QdUwMqOADlBYAY2KMNUBysQOyg0BeOQ+VSEB2A0CAAQDzcqRBlYWrmgAAAABJRU5ErkJggg==");
}

.nm-at-lk-inv.nm-el-lk-ast:hover::before {
	/* arrow lightgrey */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzI5MjRCNjVGQjgwMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzI5MjRCNjZGQjgwMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMjkyNEI2M0ZCODAxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMjkyNEI2NEZCODAxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Psxw5AAAAAB6SURBVHjaYrxw+YoAAwPDfSAG0YH6OtobGKCACcj5AKQdofz5F69cNYBLggigggtAKhGqez9MAeP///9hChmAgvOBVAIQPwBiQyYGVPABSoNMEGBC0gXSUQBV4Ai06gHYWKgd+6E6QBIHwA4CSoAE5kMlEmESIAAQYAAsuCqQjqciDAAAAABJRU5ErkJggg==");
}

.nm-at-lk-reverse.nm-el-lk-ast::before {
	/* arrow black to left */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUU4MUE2QkNGQjgxMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUU4MUE2QkRGQjgxMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMjkyNEI2QkZCODAxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMjkyNEI2Q0ZCODAxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmSAnQ0AAABDSURBVHjaYmBABQlA/B+IzwOxALKEA1TiPRAbIEsYQAX/Q3XDgQKSRD+yBBMQf4BiBnR7CBqLTYEBNgUBSC4WAAgwAHRjEsTd/jJEAAAAAElFTkSuQmCC");
}

.nm-at-lk-reverse.nm-el-lk-ast:hover::before {
	/* arrow red to left */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjZFQTI5NUYxNDBBMTFFNEE2MTdDNjBFNDEwMTcxRDgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjZFQTI5NjAxNDBBMTFFNEE2MTdDNjBFNDEwMTcxRDgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGQjYzNTZGNDE0MDExMUU0QTYxN0M2MEU0MTAxNzFEOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNkVBMjk1RTE0MEExMUU0QTYxN0M2MEU0MTAxNzFEOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnpO6HUAAABzSURBVHjaYvz//z8DDJxlNEkAUvOB+AIQOzLCJIESDkBqPxB/AEkY/z9zgQkqYQCk1kMNKARJgBhMQAkFqA4BIJ4AlFgAs4YJaswHKF+AAQkwAVWCJAKhChKgjgIDZAcZIBlvCLKXEc0rAVCHgUxRBAgwAB/ML0XXx7KxAAAAAElFTkSuQmCC");
}

.nm-el-lk-01 {
	font-size: 1.2rem;
	line-height: 1.5rem;
}

.nm-el-lk-ast.nm-el-lk-anchor::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRkM3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjM4NkY0OUM3NzY1MTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGQTc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGQjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiSjP2kAAABKSURBVHjaYrxqEPZfKNydAQgYGVDB/3crdzIwfb9wlwHEAAmgS4LkmEA60RTBJUFyLFAdIEX/3zGAFcElQQQLkrFgRTA2TBAgwADDZynL/bX51QAAAABJRU5ErkJggg==");
	height: 5px;
	left: auto;
	right: 0;
	top: 8px;
	width: 8px;
}

.nm-el-lk-ast.nm-el-lk-anchor {
	padding-left: 0;
	padding-right: 12px;
	text-decoration: underline;
}

/* TXT-LNK (A2 Text link) */

.nm-el-lk-02 {
	font-size: 1.2rem;
	line-height: 1.5rem;
	text-decoration: underline;
}

/* N-LNK (A3 navigation link) incl. Breadcrumb */

.nm-el-lk-03,
.nm-el-lk-08,
.nm-el-lk-09 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-size: 1.2rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.5rem;
}

/* N-LNK-R (A3 navigation link regular) */

.nm-el-lk-04 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* HL-LNK (A4 headline link) */

.nm-el-lk-05 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-size: 1.5rem;
	font-weight: var(--font-weight-bold);
	line-height: 2rem;
	margin: 19px 0 0;
}

.nm-el-lk-05::before {
	/* arrow: red */
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjVENkU1MkFGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjVENkU1MkJGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNUQ2RTUyOEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNUQ2RTUyOUZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkibFJIAAAC+SURBVHjabJFLCsJAEERnxiAobjxL9CIiuhLEYxizEH9H0IUKggsVD+IiHkcEkYxVUIEhseGRXjy6pjs2M/HQGLMFbZCCFWiCd+yz3KgicFV/AAv1S1AHn0J0+lIec4rkGaWn7URlkXUHE/CSnIAv5FoRHdYF8F07xVPaQM6dqdYNnNXPQRf4f+IAjNTzCg9sX4nmqfagBaaMpVR+Yw8cdcM0lMKt++AkKdEiNoxymsQ/09D91kzCNB+KPwEGAKT0L5x12GxQAAAAAElFTkSuQmCC");
	height: 11px;
	margin-right: 4px;
	width: 10px;
}

.nm-el-lk-05.nm-at-lk-inv::before {
	/* arrow: white */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzM2Mjg4QTlGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzM2Mjg4QUFGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNUQ2RTUzMEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMzYyODhBOEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pjf35sgAAACFSURBVHjaYvj//384EL/7DwE1QMwIxNxAzATEDDAMY4AUf0FSDBJjw6YQhEOA+BuaYhZsCkE4AsnkaqgYMzaFIByG5OY6IGYFuRubQhCe9B8BrPCZ+B7JrUzYFIJ8/xmqqBKXr4OA+CuarzEUhiCZVAUVY0RXCDLpNRD/QQoSFnQTAQIMAF74Z0Je26jhAAAAAElFTkSuQmCC");
}

.nm-el-lk-05.nm-at-lk-inv:hover::before {
	/* arrow: lightgrey */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjVENkU1MjZGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjVENkU1MjdGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODM4MkUyM0ZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ODM4MkUyNEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtYjhecAAADMSURBVHjabJA7CsJAEIY3cSux8RwKHsBbhKA2HsDCCxiDhUp6G1OIYOlFxFIxnsLWRxNcvw0TWR8DH7Mz8zPM/t7hlHWVUkuoQwxzqMKj1Ww8lYRPsSUP4AZTiOjZt1ZuGGMK2BzCHQyMpafL+Vsogx5cRRxJr2KzV6idOGbnDimVmyeQQP4jFPGCNJSyzc07/4/IbuxLaV3Yq++fIbJWraAGIzYl5Uw7ooC0Fg9jV1T4KKKQtBGR9XFGz/u4ie8HcIHcsUS7tlleAgwAX0aMjSsbf2oAAAAASUVORK5CYII=");
}

/* CTA-LNK (A5 call to action link) */

.nm-el-lk-06 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-size: 2.6rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.6rem;
	margin: 19px 0 0;
}

.nm-el-lk-06::after {
	/* double arrow large: red */
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExODcxRkRFNEQ4MjQzNkNCRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBRjlBQ0JFMzc1NjIxMUUyODQyMEQwOTczQTYwRTRBMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBRjlBQ0JFMjc1NjIxMUUyODQyMEQwOTczQTYwRTRBMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRENEIyM0RGMTAyMDY4MTE5MTA5Rjc4OEE1OTE1NUI3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTE4NzFGREU0RDgyNDM2Q0JFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Ru8dIAAAAW5JREFUeNqMlc9HRFEUx897ZWjVqm20mm1MYhgmMplMpF2bdu37tyIaEaWUIg2ZoVVERLQahsvwGFLfm288Ou+ce/gw1/3O+bxf995sKI05ETkBe/K/ZmAf9Dl2s43vYV+MysEXm14r8zVwDHocu9lRttbzhH9XtwsGFY1OS1I3a0nz0u8CbINnQ9pNzULa9YSxAtgCr4a0k5qFtOMJY43BJvhU5hbAGWinZiFte0Jhgw021BpdgFYc4Kt0s5C2PGGsN159MKRNSt0spL/ZDOtQnFoHd/yjaO8cwqc4QFM3m4tfH0Srd5Kc9YTL4BHUlblRfIy4uzHvLilrCetssFLRIC6JCWVuFrKJ9Q5XwRVYUuYGXPQhNQtZsL7SJl+81uCBdxZSs2WZJow7ww1YVBrcUzZNzUI2tXaaeOScV3zSt3yMRWoWssLaSw94ztWUzCXYKcncbJUs1jz3xaOKnf8FHPJIkpQsZDNrnf0IMABJRZXTh7RH5wAAAABJRU5ErkJggg==");
	content: "";
	display: inline-block;
	height: 18px;
	margin-left: 7px;
	/* vertical-align: middle; */
	width: 28px;
}

.nm-el-lk-06.nm-at-lk-inv::after {
	/* double arrow large: white */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RkJCQzUxMERGQjg5MTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RkJCQzUxMEVGQjg5MTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGQkJDNTEwQkZCODkxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGQkJDNTEwQ0ZCODkxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pqi2oaAAAAEISURBVHjanJTBBgJRFIZvRUREtIpW0SpaxRCthmjVA7TrdXqVthERMatol1ZFWrUaIkpM/+VEpnPOzL2Hj0Zf/z9x7jVJkpTAIuHnCSbAEC4uy/dDGayUoPHPj1xcsdBSAVHOIBdXLLTUwF4JGnm6YqGlAY5C0AOEnq5YaGmCqxI09HSNtlFtcFOCBj6uyVjjLoiFoDsIXN2sQkuf3pKbmL7P7RZN9lwIbs5Efjfj37XASXjjHai7ulpZR9m+dFluVyrrKVsX0aH3cdnCQNm2Lah6umxhqGzZhu5QH5ctnNAdyM06FeDisoVT8BYClqkAF/ePgr3rcD7mwtk5gBl40bOLy85HgAEA5G8s/YIDdaMAAAAASUVORK5CYII=");
}

.nm-el-lk-06.nm-at-lk-inv:hover::after {
	/* double arrow large: lightgrey */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDQ5NkJBMDRGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDQ5NkJBMDVGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NDk2QkEwMkZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NDk2QkEwM0ZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvP8DQwAAAFwSURBVHjajJXPR0RRFMfvvDK0atU2Ws2iqFUMw0Qmk4m0a9Ouff9WRCOilFKkIWLSFBERrYbhMjyG6HvzjUfnnXMOH+a63zmf9+veW+k/D6ZCCEdgJ/yvCdgFXY7N7PLSYjcolYFvNr0U5qvgEHQ4NrNPg5eOJfy7um3QK2l0XJCaWU2aFX7nYBP0FWnbm4W0bQlTRbAB3hRpy5uFtGUJUw3BOvgS5mbACWh6s5A2LWFggzU2lBqdgUYa4Ks0s5A2LGGqd159VKR1Ss0spL/ZCtZhMGoV3PCPQXrnED6kAZqa2SzY9Umk+iDurCWcB/egJsw9pseIuxvy7lxZTVhjg4WSBmlJjCgzs5CNtHe4Ai7AnDDX46KP3ixkUftK63zxUoM73ln0ZosySZh2hiswKzS4pWzszUI21naadOSclnzS13yMuTcLWa7tpXs856pC5hxsFWRmtkyWapr74kHJzv8K9nkkBU8Wsom2zn4EGADYXJXTpziKQAAAAABJRU5ErkJggg==");
}

/* CTA-LNK-S (A6 call to action link small) */

.nm-el-lk-07 {
	font-family: AudiTypeExtended, Verdana, sans-serif;
	font-size: 1.5rem;
	font-weight: var(--font-weight-bold);
	line-height: 2rem;
	margin: 19px 0 0;
}

.nm-el-lk-07::after {
	/* double arrow medium: red */
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTFDQkU5Qzc3NTNBMTFFMkExN0JGRkRDNUU4QjQzMEUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTFDQkU5Qzg3NTNBMTFFMkExN0JGRkRDNUU4QjQzMEUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBMUNCRTlDNTc1M0ExMUUyQTE3QkZGREM1RThCNDMwRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBMUNCRTlDNjc1M0ExMUUyQTE3QkZGREM1RThCNDMwRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmlDMQkAAAEMSURBVHjabNLNSgJRGMbx49BGK8FlGzcFEUQboVVXIZUQFZILceENVEqQJm0DayNJu77beQeBkLtGMqJriMASd/1feILD4IEfc5jzPuedOTOxnsvknHMXSKGCOpI4xxbeYDWvzhsBblDCD45xgG8U8YAlPGIlGnQK5zFS+BBD7OIOC7j1w4G3yT32Ip1/UcATFtV5ORq0ca3wF2qoYoxtrc2rwWqMw3ETxhnKmq/hGWl0MYdOMCG0iR3N7ZRfkMCpQgMcTUVCduwtzGAfDc2vkMUn1tH3O9rCpQorCsW1ka2969q34v+Otktbj2SneYJp3dvAh2pC/3Nk9efE9f0sNIumQqFqQv+d/gQYAEgEOrpjGgfPAAAAAElFTkSuQmCC");
	content: "";
	display: inline-block;
	height: 11px;
	margin-left: 5px;
	margin-top: 5px;
	vertical-align: top;
	width: 14px;
}

.nm-el-lk-07.nm-at-lk-inv::after {
	/* double arrow medium: white */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjgzODJFMURGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjgzODJFMUVGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODM4MkUxQkZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ODM4MkUxQ0ZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ps9QUlkAAADISURBVHjafJHLCkFRFIa3iXLLG3gBlxkTb6ETXsUlD+EJTETCzJMohLGJYuAyUtu/sk7+VptdX53+zte/19rOe98CF/85fZAAeTDWbAPKwDHxh8h3kiXLgrlmB1AJiUIEnkZOg5lmO5aduUKbmnua5cBSsyMohUShSTMPQFKbJ9RcC4nC0H9PXbMCOGm2+tV4pVlt4xZUrSTbvekPHdrugmYs2hkb4GG2mgLTf1uNqKmrWYaeYh9vk0VpOoOXeYKRSmsrCW8BBgBB3giwJIFMHwAAAABJRU5ErkJggg==");
}

.nm-el-lk-07.nm-at-lk-inv:hover::after {
	/* double arrow medium: lightgrey */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjgzODJFMjFGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjgzODJFMjJGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODM4MkUxRkZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ODM4MkUyMEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkIU9pgAAAEnSURBVHjaZJLPSgJRGMWvQwhqiS17gpCiVr2HDeZIkFvpCYIyKlKzByhd9L82BfUibiqvmETr1i50O/1unGLUgR/nzpk595vvfhN77djAGNOEediHGqThHDahB8Hq8lLHRC4P4xHdhiEcwx7eAC3DM2Th5c12V6LBWBiGvwse5JE7SLjKhKt4SdY3sAF9KOC/jwUVLiIXkIIKL9Xw5ljfwjp8QQ7fjgUVLiAt9XwADZiBSyiqcskzExe7PSEPuj2CNbwRugPfsOg2nAqq4pZu3Sm31espLMAHHHoToUCflIFdd0BoHO71ma7HPH7bi4R85ApmdaoneAkdlq/efPyuUdN/o7iGpOZYx0vJc6P4VCX7/wOoUlPzqyjkRnCmkFUlG23rR4ABAPwJb6zc1oL6AAAAAElFTkSuQmCC");
}

.nm-el-lk-08 {
	margin-right: 10px;
}

.nm-el-lk-08::after,
.nm-el-lk-09::after {
	background-position: left center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	height: 15px;
	margin-left: 10px;
	vertical-align: middle;
	width: 9px;
}

.nm-el-lk-08::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
	width: 7px;
}

.nm-el-lk-09::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGxJREFUeNpiOMNgfAmI/wMBAwij80GYiYGBYTIQXz7LaPKfAQLQ+QxMxv/PzAbS82AS6HyQIhYQAZSYABTgBrGhChmR+UwMRAAmqOoCIBUOxLpQU1D4TECBVCAnCUkBCh9mUi6yABY+A0CAAQBl9UkzJOUOdAAAAABJRU5ErkJggg==");
}

.nm-el-lk-10 {
	font-size: .75em;
	line-height: 1.1rem;
	text-decoration: underline;
}

/* buttons */

/*	.nm-el-btn-01 = B-TXT, B-TXT-G, B-CTA, B-CTA-G, B-CTA-R
	.nm-el-btn-02 = Configurator Teaser (B-CTA)
	.nm-el-btn-03 = B-BACK
	.nm-el-btn-04 = form button with arrow only
*/

/* base class for buttons - margins are set individually */

.nm-el-btn {
	align-items: center;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	font-family: Verdana, sans-serif;
	font-size: 1.2rem;
	font-weight: bold;
	/* max-width 230px defined */
	height: 40px;
	line-height: 1.5rem;
	max-width: 200px;
	overflow: hidden;
	padding: 0 15px;
	position: relative;
}

.nm-el-btn::before,
.nm-el-btn::after {
	content: "";
	vertical-align: middle;
}

.nm-el-btn.nm-el-btn-ast {
	max-width: 187px;
	padding-right: 28px;
	text-align: left;
}

.nm-el-btn.nm-el-btn-ast::after {
	height: 9px;
	margin-top: -5px;
	position: absolute;
	/* NERO 1837 */
	right: 15px;
	top: -webkit-calc(50% + 1px);
	top: calc(50% + 1px);
	width: 7px;
}

.nm-el-btn.nm-el-btn-icn::before {
	background-position: left center;
	background-repeat: no-repeat;
	height: 40px;
	left: 15px;
	position: absolute;
	top: 0;
	width: 30px;
}

.nm-el-btn.nm-el-btn-ast::after {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjQ2QjU2RjNGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjQ2QjU2RjRGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGMUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGMkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PldVk/MAAABNSURBVHjaYvj//78AEL//DwEBQMwAwzCGAVTyPZSNIgnCCegKkCVBeD5UwX2QdUwMqOADlBYAY2KMNUBysQOyg0BeOQ+VSEB2A0CAAQDzcqRBlYWrmgAAAABJRU5ErkJggg==");
}

.nm-el-btn.nm-el-btn-ast.nm-at-btn-inv::after {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
}

/* button on image */

.nm-el-btn.nm-at-btn-img {
	/* white shadow - 5px width, color 15% #fff */
	box-shadow: 0 0 0 5px rgba(255, 255, 255, .15);
}

/* inverted button on image */

.nm-el-btn.nm-at-btn-inv.nm-at-btn-img {
	/* black shadow - 5px width, color 15% #000 */
	box-shadow: 0 0 0 5px rgba(0, 0, 0, .15);
}

/* inverted button on image */

.nm-el-btn.nm-at-btn-inv.nm-at-btn-img {
	/* black shadow - 5px width, color 15% #000 */
	box-shadow: 0 0 0 5px rgba(0, 0, 0, .15);
}

/* B1 Standard-Button (B-TXT) */

.nm-el-btn-01 {
	background-color: #000;
	color: #fff;
}

.nm-el-btn-01:hover {
	background-color: #434c53;
}

.nm-el-btn-01:active {
	background-color: #6d7579;
}

.nm-el-btn-01.inactive {
	background-color: #b0b6b8;
	cursor: default;
}

.nm-el-btn.nm-at-btn-inv.nm-at-btn-img {
	box-shadow: 0 0 0 5px rgba(0, 0, 0, .15);
}

.nm-el-btn-01.nm-at-btn-trans {
	background-color: rgba(0, 0, 0, .75);
}

.nm-el-btn-01.nm-at-btn-trans:hover {
	background-color: rgba(67, 76, 83, .75);
}

/* B1 Standard-Button grey (B-TXT-G) */

.nm-el-btn-01.nm-at-btn-inv {
	background-color: #e7e9ea;
	color: #000;
}

.nm-el-btn-01.nm-at-btn-inv:hover {
	background-color: #d3d5d7;
}

.nm-el-btn-01.nm-at-btn-inv:active {
	background-color: #c6c9cb;
}

/* B2 CTA-Button red (B-CTA-R) */

.nm-el-btn-01.nm-at-btn-indicator {
	background-color: #c03;
	color: #fff;
}

.nm-el-btn-01.nm-at-btn-indicator:hover {
	background-color: #aa142d;
}

.nm-el-btn-01.nm-at-btn-indicator:active {
	background-color: #c03;
}

.nm-el-btn.nm-el-btn-ast::before {
	content: none;
}

/* B4 Filter-Button (B-FILTER) */

.nm-el-btn-01.nm-el-btn-ast-cls::after {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1MTIyOTQ2RkU1MDIxMUU0OUU3NDk5MTUzODMwOTlGNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1MTIyOTQ3MEU1MDIxMUU0OUU3NDk5MTUzODMwOTlGNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjUxMjI5NDZERTUwMjExRTQ5RTc0OTkxNTM4MzA5OUY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjUxMjI5NDZFRTUwMjExRTQ5RTc0OTkxNTM4MzA5OUY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+nBr20gAAADxJREFUeNpi+P//PwMQz4TS2DBYDsb4j0MxXA5DAJsimInYJDA04rIKwylMDMQCUq0myjMkBw/eAAcIMADyNSzkEBqamwAAAABJRU5ErkJggg==");
	content: "";
	height: 11px;
	margin-left: 10px;
	width: 11px;
}

/* Configuration Button */

.nm-el-btn-02 {
	background-color: #000;
	color: #fff;
	padding-left: 38px;
}

.nm-el-btn-02:hover {
	background-color: #434c53;
}

.nm-el-btn.nm-el-btn-02::before {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGZJREFUeNrslMEKACAIQ1v0/7+8IOgSaWl2CNpR4TnSBZIpUjkF6wNNYiSQEjQfOsMpUIVpQHpgEnD2Pluw1hSiJ+UR3rPBZo3jcG0psDjrKos+rDdVghLCa1n2OMTEGd75vqoAAwDORRYjmSpWFQAAAABJRU5ErkJggg==");
	height: 20px;
	left: 12px;
	margin-top: -10px;
	position: absolute;
	top: 50%;
	width: 30px;
}

/* Configuration Button - inverted state */

.nm-j-configurator-status_11011 .nm-el-btn-02,
.nm-j-configurator-status_11010 .nm-el-btn-02,
.nm-j-configurator-status_10011 .nm-el-btn-02,
.nm-j-configurator-status_10010 .nm-el-btn-02,
.nm-j-configurator-status_11010 .nm-el-btn-01,
.nm-j-configurator-status_11011 .nm-el-btn-01,
.nm-j-configurator-status_11011 .nm-el-btn-01,
.nm-j-configurator-status_10011 .nm-el-btn-01,
.nm-j-configurator-status_10010 .nm-el-btn-01 {
	background-color: #e7e9ea;
	color: #000;
	padding-left: 35px;
}

.nm-j-configurator-status_10011 .nm-el-btn-02 {
	cursor: default;
}

.nm-j-configurator-status_11010 .nm-el-btn-02.nm-at-btn-img,
.nm-j-configurator-status_11011 .nm-el-btn-02.nm-at-btn-img,
.nm-j-configurator-status_11011 .nm-el-btn-02.nm-at-btn-img,
.nm-j-configurator-status_10011 .nm-el-btn-02.nm-at-btn-img,
.nm-j-configurator-status_10010 .nm-el-btn-02.nm-at-btn-img,
.nm-j-configurator-status_11010 .nm-el-btn-01.nm-at-btn-img,
.nm-j-configurator-status_11011 .nm-el-btn-01.nm-at-btn-img,
.nm-j-configurator-status_11011 .nm-el-btn-01.nm-at-btn-img,
.nm-j-configurator-status_10011 .nm-el-btn-01.nm-at-btn-img,
.nm-j-configurator-status_10010 .nm-el-btn-01.nm-at-btn-img {
	box-shadow: 0 0 0 5px rgba(0, 0, 0, .15);
}

.nm-el-btn.nm-el-btn-02 .nm-at-btn-state02,
.nm-el-btn.nm-el-btn-02 .nm-at-btn-state03,
.nm-el-btn.nm-el-btn-01 .nm-at-btn-state02,
.nm-el-btn.nm-el-btn-01 .nm-at-btn-state03 {
	display: none;
}

.nm-j-configurator-status_11010 .nm-el-btn-02 .nm-at-btn-state01,
.nm-j-configurator-status_11011 .nm-el-btn-02 .nm-at-btn-state01,
.nm-j-configurator-status_11011 .nm-el-btn-02 .nm-at-btn-state01,
.nm-j-configurator-status_10011 .nm-el-btn-02 .nm-at-btn-state01,
.nm-j-configurator-status_10010 .nm-el-btn-02 .nm-at-btn-state01 {
	display: none;
}

.nm-j-configurator-status_11010 .nm-el-btn-02 .nm-at-btn-state02,
.nm-j-configurator-status_11011 .nm-el-btn-02 .nm-at-btn-state02,
.nm-j-configurator-status_11011 .nm-el-btn-02 .nm-at-btn-state02,
.nm-j-configurator-status_10011 .nm-el-btn-02 .nm-at-btn-state02,
.nm-j-configurator-status_10010 .nm-el-btn-02 .nm-at-btn-state02,
.nm-j-configurator-status_11010 .nm-el-btn-01 .nm-at-btn-state02,
.nm-j-configurator-status_11011 .nm-el-btn-01 .nm-at-btn-state02,
.nm-j-configurator-status_11011 .nm-el-btn-01 .nm-at-btn-state02,
.nm-j-configurator-status_10011 .nm-el-btn-01 .nm-at-btn-state02,
.nm-j-configurator-status_10010 .nm-el-btn-01 .nm-at-btn-state02 {
	display: block;
}

.nm-j-configurator-status_11010 .nm-el-btn-02::before,
.nm-j-configurator-status_11011 .nm-el-btn-02::before,
.nm-j-configurator-status_11011 .nm-el-btn-02::before,
.nm-j-configurator-status_10011 .nm-el-btn-02::before,
.nm-j-configurator-status_10010 .nm-el-btn-02::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGFJREFUeNpi/P//PwM1ARMDlcEINJAFXeCiqAuxekGxyaj/eg9VXPgfjabIQGRDGCk1EK9h+Az8T45huAzEFj5EGYbLQEY0g4g2DJ+XGYkUIylhM5JqGFjhaGkzAgwECDAAckEVImF/zMQAAAAASUVORK5CYII=");
}

.nm-j-configurator-status_11010 .nm-el-btn-02:hover .nm-at-btn-state02,
.nm-j-configurator-status_11011 .nm-el-btn-02:hover .nm-at-btn-state02,
.nm-j-configurator-status_11011 .nm-el-btn-02:hover .nm-at-btn-state02,
.nm-j-configurator-status_10010 .nm-el-btn-02:hover .nm-at-btn-state02 {
	display: none;
}

.nm-j-configurator-status_11010 .nm-el-btn-02:hover .nm-at-btn-state03,
.nm-j-configurator-status_11011 .nm-el-btn-02:hover .nm-at-btn-state03,
.nm-j-configurator-status_11011 .nm-el-btn-02:hover .nm-at-btn-state03,
.nm-j-configurator-status_10010 .nm-el-btn-02:hover .nm-at-btn-state03,
.nm-j-configurator-status_11010 .nm-el-btn-01:hover .nm-at-btn-state03,
.nm-j-configurator-status_11011 .nm-el-btn-01:hover .nm-at-btn-state03,
.nm-j-configurator-status_11011 .nm-el-btn-01:hover .nm-at-btn-state03,
.nm-j-configurator-status_10010 .nm-el-btn-01:hover .nm-at-btn-state03 {
	display: block;
}

.nm-j-configurator-status_11010 .nm-md-media-library-configuration-button--inactive {
	cursor: default;
	pointer-events: none;
}

.nm-j-configurator-status_11010 .nm-el-btn-02:hover::before,
.nm-j-configurator-status_11011 .nm-el-btn-02:hover::before,
.nm-j-configurator-status_11011 .nm-el-btn-02:hover::before,
.nm-j-configurator-status_10010 .nm-el-btn-02:hover::before,
.nm-j-configurator-status_11010 .nm-el-btn-01:hover::before,
.nm-j-configurator-status_11011 .nm-el-btn-01:hover::before,
.nm-j-configurator-status_11011 .nm-el-btn-01:hover::before,
.nm-j-configurator-status_10010 .nm-el-btn-01:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHJJREFUeNpi/P//PwM1ARMDlcGogQhwltHkPz45ICbeQJhh2AxFEvtPtIHG/88wYnMpmgWM2PQy4kuHuLyNbCFJkYJNIz7D6J9sCEQKA0lhiKwR5k30SAGKk5Zs0MMMLfxITzYEIoX0ZDNa2gyMgQABBgBNCT8dY/REZAAAAABJRU5ErkJggg==");
}

/* B3 Back-Button (B-BACK) */

.nm-el-btn-03 {
	background-color: #000;
	color: #fff;
	padding-left: 28px;
}

.nm-el-btn-03:hover {
	background-color: #434c53;
}

.nm-el-btn-03::before {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAJCAYAAAARml2dAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjQ2QjU2RUZGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjQ2QjU2RjBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZFREZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZFRUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtxMs8QAAABOSURBVHjaYvj//z8DEk74DwHnkQUDoILvgdgAJmgAFfgPVcCALtgPM4GJARVchLPQLP0PNYEBm4tAxgowoDm3Hyq5nxGsDRU4gAiAAAMAKTWShAyql6kAAAAASUVORK5CYII=");
	height: 9px;
	left: 15px;
	margin-top: -4px;
	position: absolute;
	top: 50%;
	width: 6px;
}

/* B4 Filter Button (B-FILTER) TODO: Check if it correlates with btn-01, refactor */

.nm-el-btn-04 {
	background-color: #000;
	height: 18px;
	text-indent: -9999px;
	white-space: nowrap;
}

.nm-el-btn.nm-el-btn-04.nm-el-btn-ast {
	height: 40px;
	max-width: 0;
	min-width: 0;
}

.nm-el-btn-04:hover {
	background-color: #434c53;
}

.nm-el-btn.nm-el-btn-04.nm-el-btn-ast::after {
	right: 17px;
}

/* B5 Option Button (B-OPT) */

.nm-el-btn-05 {
	padding-left: 32px;
}

.nm-el-btn-05::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAkUExURUxpcf///////////////////////////////////////////20p1csAAAALdFJOUwAQz2C/gFDf769AcndwiwAAAJFJREFUKM9jYKAjYNm92wGJy6jMEsDqYCQAFxCqNglgdd6uCFegJLzbgHm3oRJMCVMhQyIDgxiDuAJUgHkzA0NqGAODtQFMj3WCUOcMRbbNML6Lt4CSAKMS4xYXqMDWYLZGBgaJBNNoqMAGBtYJDAycAQzcuAQwtGAYymC9ANVaDIdhOB3DcxjexwwgjCCkOQAAmuQmPYqPeRQAAAAASUVORK5CYII=");
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	height: 32px;
	margin-left: -32px;
	width: 32px;
}

.nm-el-btn-05::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHBAMAAADDgsFQAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAhUExURUxpcf///////////////////////////////////////xzUPUwAAAAKdFJOUwDCihwZlPLznfRimV96AAAAJklEQVQI12NgAAMuFQanBQyMU40jBRgYxDsKgUKMaQIgmVUMUAAAZS4EdCDCASkAAAAASUVORK5CYII=");
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	height: 22px;
	width: 22px;
}

/*	margins between elements and modules
============================================================================= */

.nm-el-pg + .nm-el-hdl {
	margin-top: 16px;
}

.nm-el-pg + .nm-module {
	margin-top: 23px;
}

/*	media queries
============================================================================= */

/* Breakpoint 749px */

@media all and (max-width: 749px) {
	/* HLW */
	.nm-state-is-responsive .nm-el-hdl-01 {
		font-size: 2.6rem;
		line-height: 3rem;
		word-wrap: break-word;
		/* word-break: break-all;
    	word-break: break-word; */
	}

	/* HLM */
	.nm-state-is-responsive .nm-el-hdl-02 {
		font-size: 1.8rem;
		line-height: 2.4rem;
		word-wrap: break-word;
		/* word-break: break-all;
    	word-break: break-word; */
	}

	/* HL */
	.nm-state-is-responsive .nm-el-hdl-03 {
		font-size: 1.5rem;
		line-height: 2rem;
		word-wrap: break-word;
		/* word-break: break-all;
    	word-break: break-word; */
	}

	/* HLT */
	.nm-state-is-responsive .nm-el-hdl-04 {
		font-size: 1.3rem;
		line-height: 1.8rem;
		word-wrap: break-word;
		/* word-break: break-all;
    	word-break: break-word; */
	}

	/* TXT-L */
	.nm-state-is-responsive .nm-el-pg-01 {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	/* L-TXT-W   */
	.nm-state-is-responsive .nm-el-lbl-04 {
		font-size: 2.6rem;
		line-height: 3rem;
	}

	/* CTA-LNK (A5 call to action link) */
	.nm-state-is-responsive .nm-el-lk-06 {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}

	/* CTA-LNK-S (A6 call to action link small) */
	.nm-state-is-responsive .nm-el-lk-07 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}
}

html[lang="jp"] * {
	font-family: Verdana, Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
