/*
 *	FORMS
 *************************************************************************
 *  generic form elements
 *************************************************************************/

/* basics */
.nm-form {
	font: normal 12px/20px Verdana, sans-serif;
	position: relative;
}

.nm-form .nm-h2,
.nm-form h2,
.nm-legend.nm-h3 {
	padding-top: 44px;
}

.nm-form fieldset {
	clear: both;
	position: relative;
}

.nm-legend {
	margin-bottom: 20px;
	max-width: 75%;
}

.nm-stz .nm-legend,
.nm-su legend {
	display: none;
}

.nm-form .nm-cp {
	display: block; /* overwrites display: table */
	margin: 10px 0;
}

.nm-form span.nm-cp { /* TODO: Quickfix - fix with better markup */
	display: inline;
	line-height: 3.8;
}

/* form rows - wrapper class */
.nm-input-wrap {
	float: left; /* wraps a block of multiple input fields - ie. radio groups */
	margin: 10px 0;
	width: 62.51%;
}

#fdproxy .nm-input-wrap {
	display: flex;
	justify-content: space-between;
	width: 75%;
}

#fdproxy .nm-input-wrap .nm-input-label-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: 88px;
}

#fdproxy .nm-input-wrap .nm-input-label-wrapper span {
	color: #0da20d;
	max-width: 79%;
	padding-top: 12px;
}

.nm-radio .nm-input-wrap {
	margin: -7px 0 0;
}

/* rows with label, input, error */
.nm-form-item {
	margin: 2px 0;
	padding: 2px 0;
}

.nm-form-item input:disabled {
	border-bottom: none;
	color: #000;
}

.nm-form-item-multiple .nm-input {
	float: left;
}

/* rows with buttons */
.nm-button-row {
	margin-bottom: 30px;
	position: relative;
}

.nm-button-row .nm-el-btn {
	margin-left: 4px;
}

#fdproxy .nm-button-row {
	margin-bottom: 60px;
}

.nm-button-row .nm-b3 {
	margin-left: 18px;
}

/* disclaimer in forms */
.nm-form-disclaimers {
	margin: 34px 0 54px;
}

.nm-disclaimer-icon {
	float: left;
	margin-right: 5px;
}

.nm-disclaimer-icon + .nm-c2 {
	overflow: hidden; /* columnized view */
}

.nm-c2.nm-form-footnote {
	margin-bottom: 15px;
}

/* error */
.nm-form-standard-error {
	display: none;
}

.nm-form-standard-error,
.nm-error {
	background: #fff; /* covers absolute positioned and stacked elements */
	color: #c03;
	margin: 20px 0;
	padding: 0 27px;
	position: relative;
}

.nm-form-item .nm-error {
	margin: 10px 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 25%;
}

.nm-form-standard-error::before,
.nm-error::before,
.nm-form-error-image {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OThGNThDQTMzRTE1MTFFMkJCMzlFOUMzNjIxMTIwN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OThGNThDQTQzRTE1MTFFMkJCMzlFOUMzNjIxMTIwN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5OEY1OENBMTNFMTUxMUUyQkIzOUU5QzM2MjExMjA3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5OEY1OENBMjNFMTUxMUUyQkIzOUU5QzM2MjExMjA3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlGOAGwAAABPSURBVHjaYjzDYMxAOmBiIAsMbW3G/08AES5ZFlwS/3FL4dPGCCL+ke63//hkcWtjpIFtOP12lsmEnAgw+n/G6N9Jkm07x2gyQpMyQIABACEQEB5D4w4BAAAAAElFTkSuQmCC");
	content: "";
	height: 18px;
	left: 0;
	position: absolute;
	width: 18px;
}

.nm-form-error-image {
	margin: 0 10px 0 0;
}

.nm-error ~ input[type=text],
.nm-error ~ select {
	border-color: rgb(204, 0, 51);
}

/* label */
.nm-label,
.nm-form-label,
.nm-form-label-required {
	display: block;
	float: left;
	font-weight: 700;
	margin-right: 15px;
}

.nm-form-label-wrapper {
	display: inline-flex;
	margin: 13px 15px 4px 0;
	width: 100%;
}

.nm-form-label-wrapper:empty {
	margin: 0;
}

.nm-form-label-wrapper .nm-info {
	flex-shrink: 0;
	width: 22px;
}

.nm-radio .nm-form-label-wrapper {
	min-width: 35%;
	width: auto;
}

.nm-form-label-required::after {
	content: "*";
	display: inline;
	left: 2px;
	position: relative;
	top: -3px;
}

.nm-radio label {
	margin-right: 20px;
}

#nm-id-form-field-fs-products-list.nm-radio label {
	float: left;
}

/* radio and checkboxes */
.nm-radio {
	clear: both;
	margin: 0 0 15px;
}

#fdproxy .nm-radio {
	margin: 15px 0 15px 7px;
}

.nm-checkbox {
	margin: 15px 0 5px 7px;
}

.nm-checkbox > p {
	margin-bottom: 15px;
	margin-left: -7px;
}

.nm-radio input[type="radio"],
.nm-checkbox input[type="checkbox"] {
	clip: rect(0, 0, 0, 0);
	position: absolute; /* using native inputs and covering them with images */
}

.nm-radio input[type="radio"] + label::before {
	content: url("../assets/image/form-radiobutton.png");
	left: -7px;
	position: relative;
	top: 9px;
}

.nm-checkbox input[type="checkbox"] + label::before {
	content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADBJREFUeNrszCEOACAQxMDb/z96cSSgEMipqpoZHWVf2z9igkaj0Wg0Gv1A62oJMAAtvEgZYuSI8gAAAABJRU5ErkJggg==");
	display: block;
	float: left;
	left: -7px;
	position: relative;
	top: -7px;
}

.nm-radio input[type="radio"]:checked + label::before {
	content: url("../assets/image/form-radiobutton-checked.png");
}

.nm-checkbox input[type="checkbox"]:checked + label::before {
	content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFhJREFUeNrs1NEKABAMhWHzBHv/lx0RiaaFXajz3ZH+i7WEAAC/E5H9A7rsEtHLtKV7kjZ2s7heMbM2UHtXVevS9O54PDfVn3W1ussKu3R9cYEPCMBVEmAA/KVI6ngz7JEAAAAASUVORK5CYII=");
}

.nm-checkbox input[type="checkbox"]:disabled + label::before,
.nm-checkbox .disabled + label::before {
	content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEE3OTMzNDc3MUUyMTFFMjgyNTBCODUzNEQ2QUM1MTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEE3OTMzNDg3MUUyMTFFMjgyNTBCODUzNEQ2QUM1MTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowQTc5MzM0NTcxRTIxMUUyODI1MEI4NTM0RDZBQzUxOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowQTc5MzM0NjcxRTIxMUUyODI1MEI4NTM0RDZBQzUxOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pk9HvQYAAABASURBVHjaYtywbQcDbQATA80ADY1mgbP8Pd2pYuLG7TuHcoCMGj1q9KjRo0aPGj1q9KjRA9AOgbcfRnaAAAQYADEyB39wvi4QAAAAAElFTkSuQmCC");
}

/* input */
/*
body:not([data-template="oneshop"]) .nm-wrapper-content input:not(.audi-range-slider__input, .header-search__input, .header-service-search__input) {
	appearance: none;
	border-radius: 0;
	font: 12px/15px Verdana, sans-serif;
	margin: 0;
	padding: 0;
	vertical-align: bottom;
}
*/

.nm-input input {
	border: 1px solid #d0d3d4;
	box-sizing: border-box;
	padding: 12px;
}

.nm-input,
.nm-select {
	margin: 5px 0;
}

.nm-at-input-error input {
	border-color: #c03;
}

.nm-at-input-number input {
	display: inline-block;
}

.nm-form-item-datetime .nm-input {
	margin-right: 10px;
	width: 80px;
}

#nm-id-form-field-DownPayment {
	display: block; /* overwrites the default inline-block for nm-input-number, prevents the line to break */
}

#fdproxy select,
.nm-input p,
.nm-input input,
.nm-input textarea,
.legacy select {
	width: 260px;
}

#nm-id-form-field-UserDataUseAgreementInfo p {
	width: auto;
}

.nm-input textarea {
	background: transparent;
	border: none;
	border-bottom: 1px dotted #000;
	padding: 3px 0 7px;
}

.nm-input textarea {
	border: 1px dotted #000;
	height: 100px;
}

input[type="hidden"] span {
	display: inline-block;
	margin: 3px 0 0; /* overwrites default margin nm-cp (in form elements) */
	padding-bottom: 7px;
}

/* select */
#fdproxy select, .legacy select {
	appearance: none;
	background-color: transparent;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRkM3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjM4NkY0OUM3NzY1MTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGQTc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGQjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiSjP2kAAABKSURBVHjaYrxqEPZfKNydAQgYGVDB/3crdzIwfb9wlwHEAAmgS4LkmEA60RTBJUFyLFAdIEX/3zGAFcElQQQLkrFgRTA2TBAgwADDZynL/bX51QAAAABJRU5ErkJggg==");
	background-position: 95% center;
	background-repeat: no-repeat;
	background-size: 8px 5px;
	border: 1px solid #d0d3d4;
	border-radius: 0;
	box-sizing: border-box;
	color: #000;
	font: 12px/15px Verdana, sans-serif;
	height: 42px;	/* fallback font definition - indivdually set with basic typo classes */
	line-height: 19px;
	padding: 12px 18px 12px 14px;
	text-indent: .01px;
	text-overflow: "…";
}

#fdproxy select::-ms-expand, .legacy select::-ms-expand {
	display: none;
}

.nm-at-select-default-val {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjE4RkY2RDVFQjkzMTFFMjgyMDVFNDNGRjlEMEVCMzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjE4RkY2RDZFQjkzMTFFMjgyMDVFNDNGRjlEMEVCMzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGMThGRjZEM0VCOTMxMUUyODIwNUU0M0ZGOUQwRUIzNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMThGRjZENEVCOTMxMUUyODIwNUU0M0ZGOUQwRUIzNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuAkxtMAAAA0SURBVHjaYmRgYPjPACL+/2dkQAKMjIxgcSZ0AQw2UCeKADIAmcqEzXhkMSZ0AXQ2QIABAMDwGgESpmBSAAAAAElFTkSuQmCC");
	color: #6d7579 !important;
	text-overflow: "…";
}

.nm-select select:focus,
.nm-input input:focus {
	outline: none;
}

.nm-select select:focus,
.nm-input input:focus {
	outline: none;
}

.nm-form-item-datetime select {
	background-position: 80% center;
	width: 100%;
}

.nm-radio .nm-form-label-wrapper.nm-form-label-empty {
	margin: 0;
	min-width: 0;
	position: absolute;
	right: 0;
	top: 44px;
	width: auto;
}

/* tooltip */
.nm-form-item .nm-info .nm-tooltip {
	display: none;
	position: absolute;
}

.nm-form-item .nm-info:hover .nm-tooltip {
	background: #000;
	bottom: 30px;
	color: #fff;
	display: block;
	padding: 15px;
	width: 400px;
	z-index: 99;
}

.nm-form-item .nm-info .nm-tooltip p,
.nm-form-item .nm-info .nm-tooltip .nm-el-lbl {
	color: #fff;
}

#nm-id-form-field-fs-products-list .nm-info:hover .nm-tooltip {
	bottom: auto;
	left: auto;
	right: -175px;
	top: 15px;
	width: 700px;
}

#nm-id-form-item-fs-products-item .nm-form-item .nm-info:hover .nm-tooltip {
	bottom: -542px;
	left: -532px;
	width: 500px;
	z-index: 99;
}

.nm-form-item .nm-tooltip h4 {
	margin: 24px 0 11px;
}

.nm-tooltip ul {
	margin: 10px 0;
}

.nm-tooltip li {
	list-style: disc;
	margin-left: 15px;
}

/* captcha */
.nm-captcha {
	margin: 10px 0 0 323px;
}

.nm-captcha a {
	cursor: pointer;
}

.nm-captcha img {
	max-width: 260px;
}

#nm-id-form-item-Item-CaptchaCaptchaWord .nm-cp {
	float: left;
	font-weight: 700;
	width: 323px;
}

#nm-id-form-item-Item-CaptchaCaptchaWord .nm-cp::after {
	content: "*";
	display: inline;
	left: 2px;
	position: relative;
	top: -3px;
}

@media all and (min-width: 860px) {
	.nm-form-label-wrapper {
		width: 35%;
	}

	.nm-radio .nm-form-label-wrapper.nm-form-label-empty {
		right: 20%;
	}
}

@media screen and (max-width: 479px) {
	.nm-state-is-responsive .legacy input:not(.audi-range-slider__input, .header-search__input, .header-service-search__input),
	#fdproxy select,
	.legacy select,
	.nm-state-is-responsive .legacy textarea {
		font-size: 16px;
		line-height: 19px;
	}

	.nm-state-is-responsive .legacy input:not(.audi-range-slider__input, .header-search__input, .header-service-search__input) {
		height: 49px;
	}

	#mileage__extension-end {
		max-height: 38px;
	}

	#fdproxy select, .legacy select {
		height: 41px;
		padding-bottom: 8px;
		padding-top: 8px;
	}
}

@media screen and (max-width: 859px) {
	#nm-id-form-field-fs-products-list .nm-input-wrap {
		display: block;
	}

	fieldset #nm-id-form-field-fs-products-list .nm-input-wrap .nm-input-label-wrapper {
		min-height: 50px;
		align-items: flex-start;
	}

	#nm-id-form-field-fs-products-list .nm-form-label-empty {
		right: 0;
	}
}

@media screen and (min-width: 749px) {
	.nm-form-item-multiple .nm-input {
		margin-right: 7px;
	}
}
