/*	=========================================================================
	Header Overlay
	========================================================================= */

/* note: the css scope nm-is-half-way-special can be used to cater the markets with dropdowns, ie cn, ca */

/* overlay layer - default */
.nm-navigation-header-overlay {
	background: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, .2);
	height: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	transition: all .3s ease-in-out .3s;
	width: 100%;
	z-index: 99;
}

/* layer for versions where the layer opens with a model link */
.nm-navigation-header-model-overlay {
	background-color: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5);
	display: none;
	max-width: 1400px;
	min-height: 630px;
	position: absolute;
	top: 70px;
	width: 100%;
	z-index: 999;
}

.nm-header-overlay-is-open.nm-navigation-header-overlay {
	padding-top: 30px;
}

/* Carline Flyout */
/* carline navigation */
.nm-navigation-header-detail-container {
	margin: 60px auto 0;
	max-width: 1177.6px;
	position: relative;
	width: 92%;
	z-index: -1;
}

.nm-navigation-header-detail {
	width: 100%;
}

.nm-navigation-header-detail-container.nm-navigation-header-detail-container-carline::after {
	background-color: #d0d3d4;
	content: "";
	display: block;
	height: 100%;
	left: 30.22%;
	position: absolute;
	top: 0;
	width: 1px;
	z-index: 10;
}

/* carline links */
.nm-navigation-header-detail .nm-navigation-header-detail-link {
	border-bottom: 1px solid #d0d3d4;
	box-sizing: border-box;
	display: block;
	padding: 13px 115px 13px 16px;
	position: relative;
	width: 30.37%;
}

/* carline link images */
.nm-navigation-header-detail .nm-navigation-header-detail-link > span {
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: auto 90%;
	height: 41px;
	position: absolute;
	right: 15px;
	top: -3px;
	width: 100px;
}

/* first link needs a border */
.nm-navigation-header-detail:first-child .nm-navigation-header-detail-link {
	border-top: 1px solid #d0d3d4;
}

/* carline image */
.nm-navigation-header-detail-image-container .nm-navigation-header-detail-image {
	background-size: cover;
}

.nm-navigation-header-detail .nm-navigation-header-detail-overlay {
	height: 0;
	opacity: 0;
	transition: 0s opacity 1s;
	width: 69.63%;
}

.nm-header-overlay-is-open .nm-header-detail-link-active .nm-navigation-header-detail-overlay {
	height: auto;
	opacity: 1;
}

.nm-navigation-header-detail-link {
	background-position: 95% center;
	background-repeat: no-repeat;
	display: block;
	line-height: 20px;
	padding: 7px 0;
}

.nm-navigation-header-detail-link:hover {
	background-color: #f2f2f2;
	border-bottom-color: #f2f2f2;
	border-top: 1px solid #f2f2f2;
	margin-top: -1px;
}

.nm-navigation-header-detail:first-child .nm-navigation-header-detail-link:hover {
	border-top-color: #e7e9ea;
	margin-top: 0;
}

.nm-header-detail-link-active .nm-navigation-header-detail-link {
	color: #4c4c4c;
}

.nm-navigation-header-detail-overlay {
	background: #fff no-repeat top right;
	left: 30.37%;
	position: absolute;
	right: 0;
	top: 0;
	visibility: hidden;
	z-index: 0;
}

.nm-navigation-header-detail-overlay-link {
	display: block;
	height: 100%;
	width: 100%;
}

.nm-navigation-header-detail-overlay .nm-navigation-header-detail-overlay-btn {
	margin: 13px 0 17px;
}

.nm-navigation-header-detail-overlay .nm-navigation-header-detail-overlay-content > ul {
	margin-bottom: 8px;
}

.nm-navigation-header-detail-overlay .nm-price {
	line-height: 1.5rem;
	margin-bottom: 2px;
}

.nm-navigation-header-detail-overlay .nm-additional-rate {
	display: block;
	margin-bottom: 27px;
}

.nm-navigation-header-detail-overlay dt {
	display: block;
	float: left;
	margin-right: 2px;
}

.nm-navigation-header-detail-overlay-content {
	position: relative;
}

.nm-navigation-header-overlay li:first-child .nm-navigation-header-detail-overlay,
.nm-navigation-header-overlay li.nm-header-detail-link-active .nm-navigation-header-detail-overlay {
	visibility: visible;
}

/* breadcrumb */
.nm-navigation-header-breadcrumb {
	margin: 0 15px 31px;
	width: 100%;
}

.nm-navigation-header-breadcrumb-item {
	float: left;
}

/* the arrows are attached to the list items so they don't change their colour on :hover */
.nm-navigation-header-breadcrumb-item:first-child::before {
	content: " ";
	margin: 0;
}

.nm-navigation-header-breadcrumb-item::before {
	content: ">";
	margin: 0 8px;
}

.nm-navigation-header-detail-image-container {
	height: 0;
	margin-bottom: 24px;
	overflow: hidden;
	padding-bottom: 38.414634146341%;
	position: relative;
}

.nm-navigation-header-detail-image-container .nm-navigation-header-detail-image {
	background-color: #fff;
	background-image: url("../images-dummy/dmy-flyout-big.png");
	background-position: bottom right;
	background-repeat: no-repeat;
	bottom: 0;
	display: none;
	height: 100%;
	position: absolute;
	right: 0;
	width: 100%;
}

.nm-navigation-header-detail.nm-header-detail-link-active .nm-navigation-header-detail-image-container .nm-navigation-header-detail-image {
	display: block;
}

.nm-navigation-header-detail .nm-navigation-header-detail-image-loading-container {
	display: none; /* prevent flyout images to be loaded per default */
}

.nm-header-overlay-is-open .nm-navigation-header-detail .nm-navigation-header-detail-image-loading-container,
.nm-navigation-header-model-overlay.flyout-is-open .nm-navigation-header-detail .nm-navigation-header-detail-image-loading-container {
	display: block; /* load flyout image when flyout is opened */
}

.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name {
	box-sizing: border-box;
	display: block;
	margin-bottom: 17px;
	padding-left: 30px;
	width: 100%;
}

/* new CI - interim style TODO: Needs to be shifted to basic elements */
.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 4.2rem;
	font-weight: var(--font-weight-bold);
	line-height: 6.6rem;
}

.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name .nm-icon-forward-large {
	height: 4.8rem;
	vertical-align: middle;
	width: 4.8rem;
}

.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name span {
	margin-right: -10px; /* workaround for icon with artboard */
}

.nm-header-is-half-way-special .nm-navigation-header-model-overlay {
	top: 100px;
}

.nm-navigation-header-model-overlay.flyout-is-open {
	display: block;
}

.nm-navigation-header-model-overlay .nm-navigation-header-detail-container,
.nm-header-inner-wrapper.nm-has-additional-logo .nm-navigation-header-model-overlay .nm-navigation-header-detail-container {
	margin-top: 10px;
	width: 100%;
}

.nm-navigation-model-subnavigation > ul {
	height: 50px;
	padding-top: 10px;
}

.nm-navigation-header-wrapped-link {
	float: left;
	margin-left: 10px;
	margin-right: 10px;
	padding: 10px 0;
	position: relative;
	z-index: 2000;
}

.nm-navigation-header-wrapped-link.active {
	color: #4c4c4c;
}

.nm-navigation-model-container {
	display: none;
}

.nm-navigation-model-container .nm-navigation-header-detail-overlay {
	top: 59px;
}

.nm-navigation-model-container .nm-navigation-header-detail .nm-navigation-header-detail-overlay {
	transition: 0s opacity 0s;
}

.nm-navigation-model-container .nm-header-detail-link-active .nm-navigation-header-detail-overlay {
	opacity: 1;
	visibility: visible;
}

.nm-navigation-model-container .nm-header-detail-link-active .nm-navigation-header-detail-overlay-content {
	visibility: visible;
}

.nm-navigation-model-container.nm-navigation-model-container-active {
	display: block;
}

.nm-navigation-model-container .nm-detail-info {
	box-sizing: border-box;
	display: inline-block;
	padding: 0 20% 0 30px;
	vertical-align: top;
	width: 61.26%;
}

.nm-navigation-model-container .nm-detail-links {
	display: inline-block;
	padding-top: 2px;
	vertical-align: top;
	width: 37.37%;
}

.nm-navigation-model-container .nm-detail-links li {
	margin-bottom: 10px;
}

.nm-navigation-header-overlay .nm-detail-content {
	display: block;
	width: 100%;
}

.nm-navigation-header-overlay .nm-detail-info,
.nm-navigation-header-overlay .nm-detail-links {
	display: inline-block;
	vertical-align: top;
}

.nm-navigation-header-overlay .nm-detail-info {
	box-sizing: border-box;
	padding: 2px 20% 0 30px;
	vertical-align: top;
	width: 61.26%;
}

.nm-navigation-header-overlay .nm-detail-links {
	width: 37.37%;
}

.nm-navigation-header-overlay .nm-detail-links li {
	margin-bottom: 10px;
}

.nm-navigation-header-overlay .nm-detail-links li a {
	display: block;
}

.nm-navigation-header-overlay .nm-detail-links li a .nm-detail-links-text {
	display: inline-block;
	height: 2rem;
	max-width: calc(96% - 16px);
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	white-space: nowrap;
	word-break: break-all;
}

/* linklist in sitemap */
.nm-navigation-sitemap-linklist-overview-item {
	display: none;
}

.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-item a {
	max-width: 96%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.nm-navigation-menu-wrap {
	margin-left: -4.22%;
}

.nm-navigation-sitemap-wrap,
.nm-navigation-models-wrap {
	display: inline-block;
	margin: 0 0 0 4.22%;
	vertical-align: top;
	width: 20.4%;
}

.nm-navigation-header-detail-container .nm-navigation-sitemap-wrap-quicklink {
	margin-bottom: 43px;
}

.nm-header .nm-navigation-sitemap-wrap-quicklink .nm-link-basic {
	align-items: center;
	display: inline-flex;
	flex-flow: row nowrap;
}

/* fallback, if wrong data for icons */
.nm-header .nm-navigation-sitemap-wrap-quicklink .nm-link-basic svg {
	height: 2.4rem;
	margin-right: 10px;
	width: 2.4rem;
}

.nm-header .nm-icon-myaudi-small {
	display: inline-block;
	fill: currentColor;
	height: 2.4rem;
	margin-right: 10px;
	stroke: currentColor;
	stroke-width: 0;
	width: 2.4rem;
}

.nm-header .nm-icon-testdrive-small {
	display: inline-block;
	fill: currentColor;
	height: 2.4rem;
	margin-right: 10px;
	stroke: currentColor;
	stroke-width: 0;
	width: 2.4rem;
}

.nm-header .nm-icon-onlineservice-small {
	display: inline-block;
	fill: currentColor;
	height: 2.4rem;
	margin-right: 10px;
	stroke: currentColor;
	stroke-width: 0;
	width: 2.4rem;
}

.nm-header .nm-icon-contact-small {
	display: inline-block;
	fill: currentColor;
	height: 2.4rem;
	margin-right: 10px;
	stroke: currentColor;
	stroke-width: 0;
	width: 2.4rem;
}

.nm-navigation-models-wrap img {
	height: auto;
	width: 100%;
}

.nm-navigation-models-wrap .nm-navigation-models-link span {
	display: block;
	margin-bottom: 13px;
	margin-top: 13px;
}

.nm-navigation-models-wrap .nm-navigation-homepage-link {
	display: inline-block;
	margin-bottom: 24px;
}

.nm-navigation-sitemap-wrap-link .nm-icon-arrow {
	display: none; /* dropdown toggle buttons are only visible in smaller viewports */
}

.nm-navigation-sitemap-linklist {
	margin-bottom: 23px;
}

.nm-navigation-sitemap-linklist li a {
	max-width: 96%;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* intermediate state until software switches completely to svg */
.nm-navigation-sitemap-wrap .nm-el-lk.nm-el-lk-icn {
	height: 2.4rem;
	padding-left: 35px;
}

.nm-navigation-sitemap-wrap .nm-el-lk.nm-el-lk-icn::before {
	background-color: transparent;
	height: 2.4rem;
	width: 2.4rem;
}

.nm-navigation-sitemap-wrap .nm-el-lk:hover {
	color: #4c4c4c;
}

.nm-navigation-sitemap-wrap .nm-el-lk.nm-el-lk-icn:hover::before {
	background-color: transparent;
}

/* search, social, close */
.nm-interact {
	bottom: 15px;
	clear: both;
	height: 0;
	overflow: visible;
	position: absolute;
	width: 100%;
}

.nm-interact-item-wrap {
	margin: 0 auto;
	max-width: 1177.6px;
	position: relative;
	right: -5px;
	/* respect the glow! */
	width: 92%;
}

.nm-interact a.nm-close {
	display: block;
	float: right;
	margin-top: -48px;
}

.nm-interact .nm-icon-cancel {
	fill: #000;
	height: 4.8rem;
	stroke: #000;
	stroke-width: 0;
	width: 4.8rem;
}

.nm-interact .nm-close span {
	display: none;
}

/* this overwrites basic styles that should stay for some time until CI switch is completed TODO: delete then */
.nm-interact .nm-icon-close::before {
	background-image: none;
	height: 0;
	width: 0;
}

.nm-header-is-half-way-special .nm-navigation-header-detail-container {
	margin: 90px auto 0;
}

@media screen and ( max-width: 1440px ) {
	.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name {
		font-size: 3.6rem;
		line-height: 5.4rem;
	}
}

@media all and (max-width: 1239px) {
	.nm-header-inner-wrapper.nm-navigation-is-dropdown .nm-navigation-header-model-overlay .nm-navigation-header-detail-container {
		margin-top: 10px;
		width: 92%;
	}
}

@media screen and ( max-width: 1024px ) {
	.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name {
		font-size: 3rem;
		line-height: 4.2rem;
	}
}

@media screen and ( max-width: 999px ) {
	.nm-navigation-header-detail-overlay-content > .nm-navigation-header-car-name {
		font-size: 2.4rem;
		line-height: 3.6rem;
	}

	.nm-navigation-header-model-overlay {
		display: none;
	}

	.nm-navigation-header-detail-container.nm-navigation-header-detail-container-carline {
		display: none;
	}
}

@media screen and (min-width: 999px) {
	.nm-navigation-sitemap-wrap .nm-navigation-sitemap-wrap-link {
		margin-bottom: 11px;
	}
}

@media screen and (max-width: 899px) {
	.nm-navigation-header-overlay.nm-header-overlay-is-open {
		height: auto !important;
		padding-top: 0;
	}

	.nm-navigation-header-overlay .nm-navigation-header-detail-container {
		border-top: 1px solid #fff;
		width: 100%;
	}

	.nm-navigation-header-overlay .nm-navigation-menu-wrap {
		margin-left: 0;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap,
	.nm-header-inner-wrapper .nm-navigation-models-wrap {
		display: block;
		margin: 0;
		width: 100%;
	}

	.nm-header-inner-wrapper .nm-navigation-models-wrap img,
	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap.nm-navigation-sitemap-wrap-quicklink,
	.nm-navigation-header-overlay:not(.nm-navigation-header-search-overlay) .nm-interact {
		display: none;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist {
		height: 0;
		margin: 0;
		overflow: hidden;
		transition: height .3s ease-in-out .3s;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-overview-item {
		display: block;
	}

	.nm-header-inner-wrapper .nm-navigation-models-wrap a {
		display: block;
		margin: 0;
		padding: 16px 4% 12px;
		width: 92%;
	}

	.nm-navigation-models-wrap .nm-header-inner-wrapper .nm-navigation-models-link span {
		display: inline-block;
		margin: 0;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-item,
	.nm-header-inner-wrapper .nm-navigation-models-wrap > a,
	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap {
		border-bottom: 1px solid #ccc;
		margin: 0;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist a,
	.nm-header-inner-wrapper .nm-navigation-models-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist a,
	.nm-header-inner-wrapper .nm-navigation-models-wrap a,
	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap a {
		display: block;
		font-family: AudiTypeWide, sans-serif; /* item is a headline small and changes it's look in smaller viewports */
		font-size: 1.4rem;
		margin: 0;
		padding: 15px 4% 13px;
		width: 92%;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist .nm-icon-arrow,
	.nm-header-inner-wrapper .nm-navigation-models-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist .nm-icon-arrow {
		display: none; /* hide basic-link icons in dropdown */
	}

	.nm-header-inner-wrapper .nm-navigation-models-wrap > a.nm-navigation-models-link {
		padding: 0 4%;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap > .nm-navigation-sitemap-wrap-link {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-flow: row nowrap;
	}

	.nm-header-inner-wrapper .nm-navigation-models-wrap > a {
		background-image: none;
	}

	/* Header Navigation */
	.nm-navigation-menu-wrap .nm-navigation-sitemap-wrap .nm-icon-arrow {
		display: block;
		fill: #000;
		height: 2rem;
		margin-right: 4%;
		transform: rotate(90deg);
		width: 2rem;
	}

	.nm-navigation-sitemap-wrap.nm-flyout-is-expanded > .nm-navigation-sitemap-wrap-link .nm-icon-arrow {
		transform: rotate(-90deg);
	}

	.nm-navigation-sitemap-wrap:hover .nm-icon-arrow {
		fill: #4c4c4c;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap > .nm-navigation-sitemap-wrap-link > a {
		pointer-events: none;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-item:last-child,
	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap:last-child .nm-navigation-sitemap-wrap-link {
		border: none;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-item .nm-el-lk.nm-el-lk-ast {
		padding-left: 0;
	}

	.nm-navigation-models-wrap > a,
	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-item .nm-el-lk.nm-el-lk-ast::before {
		background: none;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-linklist-item a {
		margin-bottom: 0;
	}

	.nm-header-inner-wrapper .nm-navigation-sitemap-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist,
	.nm-header-inner-wrapper .nm-navigation-models-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist {
		background-color: #f2f2f2;
		border-top: 1px solid #ccc;
		height: auto;
		margin: 0;
		padding: 0;
	}

	.nm-header-nav-items {
		display: none;
	}

	.nm-navigation-header-sitemap .nm-navigation-header-link {
		margin-right: 0;
	}

	.nm-navigation-header-sitemap .nm-navigation-header-link {
		margin-right: 25px;
	}
}

@media screen and (max-width: 749px) {
	.nm-navigation-sitemap-wrap,
	.nm-navigation-models-wrap {
		width: 100%;
	}

	.nm-navigation-sitemap-wrap-quicklink {
		width: 40%;
	}

	.nm-navigation-sitemap-wrap.nm-navigation-sitemap-wrap-quicklink {
		display: none;
	}

	.nm-navigation-sitemap-linklist {
		height: 0;
		margin: 0;
		overflow: hidden;
		transition: height .3s ease-in-out .3s;
	}

	.nm-navigation-sitemap-linklist-overview-item {
		display: block;
	}

	.nm-navigation-models-wrap a {
		display: block;
		margin: 0;
		padding: 16px 4% 12px;
		width: 92%;
	}

	.nm-navigation-models-link span {
		display: inline-block;
		margin: 0;
	}

	.nm-navigation-models-wrap .nm-navigation-models-link span {
		display: block;
		margin: 13px 0;
	}

	.nm-navigation-sitemap-linklist-item,
	.nm-navigation-sitemap-wrap {
		border-bottom: 1px solid #ccc;
		margin: 0;
	}

	.nm-navigation-sitemap-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist a,
	.nm-navigation-sitemap-wrap a {
		display: block;
		margin: 0;
		padding: 14px 4%;
		width: 92%;
	}

	.nm-navigation-sitemap-wrap > .nm-navigation-sitemap-wrap-link {
		cursor: pointer;
		margin-bottom: 0;
	}

	.nm-navigation-sitemap-wrap > .nm-navigation-sitemap-wrap-link > a {
		background-color: transparent;
		background-position: right 4% center;
		background-repeat: no-repeat;
	}

	.nm-navigation-sitemap-wrap > .nm-navigation-sitemap-wrap-link.nm-has-sub-nav > a {
		pointer-events: none;
	}

	.nm-navigation-sitemap-linklist-item:last-child,
	.nm-navigation-sitemap-wrap:last-child .nm-navigation-sitemap-wrap-link {
		border: none;
	}

	.nm-navigation-sitemap-linklist-item .nm-el-lk.nm-el-lk-ast {
		padding-left: 0;
	}

	.nm-navigation-sitemap-linklist-item .nm-el-lk.nm-el-lk-ast::before {
		background: none;
	}

	.nm-navigation-sitemap-linklist-item a {
		margin-bottom: 0;
	}

	.nm-navigation-sitemap-wrap.nm-flyout-is-expanded .nm-navigation-sitemap-linklist {
		background-color: #e8e9eb;
		border-top: 1px solid #ccc;
		height: auto;
		margin: 0;
		padding: 0;
	}
}
