/***************************************************************
	Sitemap
***************************************************************/

.nm-footer .nm-footer-sitemap {
	background-color: #f2f2f2;
	margin: 0;
	width: 100%;
}

/* Dropdown-Link with Arrow */
.nm-footer .nm-navigation-sitemap-wrap > .nm-footer-sitemap-wrap-link {
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
}

.nm-footer .nm-footer-sitemap-link,
.nm-footer .nm-footer-sitemap-headline-link {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Subitems in dropdownlist */
.nm-footer .nm-footer-sitemap-column.nm-flyout-is-expanded .nm-footer-sitemap-subnav-link {
	background-color: #e5e5e5;
	display: block;
	margin: 0;
	padding: 14px 4%;
	width: 92%;
}

/* Footer Navigation */
.nm-footer .nm-footer-sitemap-item {
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
}

.nm-footer .nm-footer-sitemap-overview-item,
.nm-footer .nm-footer-sitemap-item .nm-icon-arrow {
	display: none;
}

.nm-footer-sitemap-item-text {
	display: inline-block;
}

.nm-footer-sitemap-wrap {
	display: flex;
	flex-flow: row wrap;
}

.nm-footer .nm-footer-sitemap-column {
	display: block;
}

@media all and (max-width: 767px) {
	.nm-footer .nm-footer-sitemap-column {
		display: block;
		margin: 0;
		width: 100%;
	}

	.nm-footer .nm-navigation-sitemap-wrap {
		border-bottom: 1px solid #d9d9d9;
	}

	.nm-footer .nm-footer-sitemap-item.nm-has-sub-nav .nm-icon-arrow {
		display: flex;
		fill: #000;
		height: 2rem;
		margin-right: 4%;
		transform: rotate(90deg);
		width: 2rem;
	}

	.nm-footer-sitemap-children {
		display: none;
	}

	.nm-footer .nm-flyout-is-expanded .nm-footer-sitemap-children {
		background-color: #e5e5e5;
		display: block;
		padding: calc(var(--space-xxl) - var(--space-l) / 2) 0;
	}

	.nm-footer .nm-flyout-is-expanded .nm-footer-sitemap-item .nm-icon-arrow {
		transform: rotate(-90deg);
	}

	.nm-footer .nm-flyout-is-expanded .nm-footer-sitemap-children .nm-footer-sitemap-item {
		border-bottom: none;
	}

	.nm-footer .nm-footer-sitemap-overview-item {
		display: block;
	}

	.nm-footer .nm-footer-sitemap-headline-link,
	.nm-footer .nm-footer-sitemap-children .nm-footer-sitemap-link {
		box-sizing: border-box;
		width: 100%;
	}

	.nm-footer .nm-footer-sitemap-headline-link {
		padding: calc(var(--space-m)) 4%;
	}

	.nm-footer .nm-footer-sitemap-item > .nm-footer-sitemap-link {
		padding: calc(var(--space-l) / 2) 4%;
		width: 100%;
	}

	.nm-footer .nm-footer-sitemap-item.nm-has-sub-nav > .nm-footer-sitemap-headline-link {
		pointer-events: none;
	}
}

@media all and (min-width: 768px) {
	/* dropdown changes to 3 column link list */
	.nm-footer .nm-footer-sitemap-column {
		margin: 0 0 var(--space-xxl);
		width: 33.33%;
	}

	.nm-footer .nm-footer-sitemap-column .nm-footer-sitemap-item:only-child  .nm-footer-sitemap-headline-link,
	.nm-footer .nm-footer-sitemap-column .nm-footer-sitemap-children .nm-footer-sitemap-item:last-child .nm-footer-sitemap-link {
		padding-bottom: 0;
	}

	.nm-footer .nm-navigation-sitemap-wrap .nm-navigation-sitemap-wrap-link {
		margin-bottom: 0;
	}

	.nm-footer .nm-footer-sitemap-wrap {
		box-sizing: border-box;
		margin: 0 auto;
		max-width: 1177.6px;
		min-width: 0;
		padding: var(--space-xxl) 0 0;
		width: 92%;
	}

	.nm-footer .nm-footer-sitemap-item-text {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: nowrap;
		word-break: break-all;
	}

	.nm-footer .nm-footer-sitemap-headline-link .nm-footer-sitemap-item-text {
		height: 3rem;
	}

	.nm-footer .nm-footer-sitemap-link .nm-footer-sitemap-item-text {
		height: 2rem;
	}

	.nm-footer .nm-footer-sitemap-link {
		max-width: 100%;
		padding-bottom: calc(var(--space-m) / 2);
		padding-top: calc(var(--space-m) / 2);
		width: calc(100% - var(--space-xl));
	}

	.nm-footer .nm-footer-sitemap-headline-link {
		max-width: 100%;
		padding-bottom: calc(var(--space-m) / 2);
		width: calc(100% - var(--space-xl));
	}
}

@media all and (min-width: 1024px) {
	.nm-footer .nm-footer-sitemap-column {
		width: 25%;
	}

	.nm-footer .nm-footer-sitemap-headline-link,
	.nm-footer .nm-footer-sitemap-link {
		width: calc(100% - var(--space-xxl));
	}
}

@media all and (min-width: 1440px) {
	[page-type="widescreen"] .nm-footer .nm-footer-sitemap-wrap {
		max-width: 1920px;
	}

	[page-type="widescreen"] .nm-footer .nm-footer-sitemap-column {
		width: 16.66%;
	}

	[page-type="widescreen"] .nm-footer .nm-footer-sitemap-headline-link,
	[page-type="widescreen"] .nm-footer .nm-footer-sitemap-link {
		width: calc(100% - var(--space-xxl));
	}
}
