/*	nm-anchor-navigation-list Link-List / Dynamic Anchor Navigation
========================================================================== */
.nm-anchor-navigation-list {
	background-color: var(--color-white);
	display: block;
	height: 48px;
	overflow: visible;
	position: relative;
	width: 100%;
	z-index: 7;
}

.nm-anchor-navigation-list.nm-anchor-navigation-list--no-margin {
	margin-bottom: 0;
}

.nm-anchor-navigation-list[is-sticky="true"] {
	left: 0;
	margin: 0 auto;
	max-width: 1400px;
	position: fixed;
	top: 0;
}

.nm-anchor-navigation-list[is-sticky="true"] + * {
	margin-top: 100px; /* arbitrary value to suppress content jump on sticky */
}

[page-type="widescreen"] .nm-anchor-navigation-list[is-sticky="true"] {
	max-width: 1920px;
}

.nm-anchor-navigation-list.nm-anchor-navigation-list[is-sticky="true"] { /* double chaining overwrites .nm-el-pg + .nm-module rule */
	margin-top: 0;
}

.nm-anchor-navigation-list[is-flyout="true"] .nm-anchor-navigation-list__navigation,
.nm-anchor-navigation-list .nm-anchor-navigation-list__flyout-button {
	box-shadow: 0 10px 20px -10px rgba(0, 0, 0, .1);
}

.nm-anchor-navigation-list[is-flyout="true"][is-flyout-open] .nm-anchor-navigation-list__navigation {
	box-shadow: none;
}

.nm-anchor-navigation-list__flyout-button {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	overflow-x: hidden;
	padding: var(--space-s) var(--space-column);
	text-align: left;
	width: 100%;
	z-index: 2;
}

.nm-anchor-navigation-list__flyout-toggle-icon {
	align-self: center;
	height: 24px;
	margin-left: var(--space-xxl);
	transition: transform .4s ease-in-out;
	width: 24px;
}

.nm-anchor-navigation-list__navigation-list {
	background-color: var(--color-grey-05);
	max-height: 0;
	overflow: hidden;
	transition: max-height .7s ease-in-out;
	white-space: nowrap;
	z-index: 1;
}

.nm-anchor-navigation-list[is-flyout-open="true"] .nm-anchor-navigation-list__navigation-list {
	max-height: 1500px;
}

.nm-anchor-navigation-list__item {
	display: block;
	opacity: 0;
	transform: translateY(-10px);
	transition: transform .4s ease-in-out .4s, opacity .3s ease-in .4s;
}

.nm-anchor-navigation-list[is-flyout-open="true"] .nm-anchor-navigation-list__item {
	opacity: 1;
	transform: translateY(0px);
	transition: transform .5s ease-in-out 1.5s, opacity .2s ease-in 0s;
	transition-delay: 0s;
}

.nm-anchor-navigation-list[is-flyout-open="true"] .nm-anchor-navigation-list__flyout-toggle-icon {
	transform: rotate(180deg);
}

.nm-anchor-navigation-list__flyout-button-label {
	display: block;
	width: calc(100% - 24px - var(--space-xxl));
}

.nm-anchor-navigation-list__link,
.nm-anchor-navigation-list__link-label {
	cursor: pointer;
	display: block;
	width: 100%;
}

.nm-anchor-navigation-list__link--selected {
	font-weight: var(--font-weight-bold);
}

.nm-anchor-navigation-list__link {
	box-sizing: border-box;
	padding: calc(var(--space-m) / 2) var(--space-column);
}

.nm-anchor-navigation-list__item:first-child .nm-anchor-navigation-list__link {
	padding-top: var(--space-m);
}

.nm-anchor-navigation-list__item:last-child .nm-anchor-navigation-list__link {
	padding-bottom: var(--space-m);
}

@media all and (min-width: 768px) {
	.nm-anchor-navigation-list[is-flyout="false"] {
		box-shadow: 0 10px 20px -10px rgba(0, 0, 0, .1);
		height: auto;
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__navigation-wrapper {
		margin: 0 auto;
		max-width: 1177.6px;
		width: 92%;
	}

	[page-type="widescreen"] .nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__navigation-wrapper {
		box-sizing: border-box;
		max-width: 1920px;
		padding: 0 var(--space-column);
		width: 100%;
	}

	[page-type="widescreen"] .nm-3col .nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__navigation-wrapper {
		padding: 0 var(--space-side-margin);
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__flyout-button {
		padding: var(--space-s) 0;
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__navigation {
		background-color: var(--color-white);
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__flyout-button {
		display: none;
		pointer-events: none;
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__navigation-list {
		background-color: var(--color-white);
		display: flex;
		max-height: none;
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__item {
		margin: 0;
		opacity: 1;
		transform: none;
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__link {
		padding: var(--space-s) calc(var(--space-xxl) / 2) var(--space-s) calc(var(--space-xxl) / 2);
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__item:first-child .nm-anchor-navigation-list__link {
		padding-left: 0;
	}

	.nm-anchor-navigation-list[is-flyout="false"] .nm-anchor-navigation-list__link:hover {
		color: var(--color-grey-70);
	}
}

@media all and (min-width: 1280px) and (max-width: 1399px) {
	.nm-anchor-navigation-list[is-sticky=true][is-flyout="true"] .nm-anchor-navigation-list__flyout-button,
	.nm-anchor-navigation-list[is-sticky=true][is-flyout="true"] .nm-anchor-navigation-list__link-label {
		margin: 0 auto;
		max-width: calc(1177.6px + 8%);
		width: 100%;
	}
}

@media all and (min-width: 1400px) {
	.nm-anchor-navigation-list[is-sticky=true] {
		left: 50%;
		transform: translateX(-50%);
	}

	.nm-anchor-navigation-list[is-sticky=true][is-flyout="true"] .nm-anchor-navigation-list__flyout-button,
	.nm-anchor-navigation-list[is-sticky=true][is-flyout="true"] .nm-anchor-navigation-list__link-label {
		margin: 0 auto;
		max-width: 92%;
	}
}

[page-type="widescreen"] .nm-anchor-navigation-list[is-sticky=true][is-flyout="true"] .nm-anchor-navigation-list__flyout-button,
[page-type="widescreen"] .nm-anchor-navigation-list[is-sticky=true][is-flyout="true"] .nm-anchor-navigation-list__link-label {
	margin: 0;
	max-width: none;
	width: 100%;
}
