/*	=========================================================================
	Header - Navigation
		.nm-header-inner-wrapper = scope for basic positioning regarding header
		.nm-header-is-half-way-special = scope for two row styled headers
========================================================================= */

.nm-header-inner-wrapper {
	margin: 0 auto;
	position: relative;
}

/* additional logo */
.nm-header-inner-wrapper.nm-header-is-half-way-special {
	height: 100px;
}

.nm-header-inner-wrapper .nm-navigation-mobile-only {
	display: none;
}

@media screen and (max-width: 1110px) {
	.nm-header-inner-wrapper {
		box-sizing: border-box;
		height: 50px;
	}
}

@media screen and (max-width: 999px) {
	.nm-header-inner-wrapper .nm-navigation-mobile-only {
		display: block;
	}

	.nm-header-inner-wrapper .nm-navigation-desktop-only {
		display: none;
	}
}
